import React from "react";
import { Link } from 'react-router-dom';

export default function Button1(props) {

    return (
        <>
            <div className="col-lg-12 mt-5 mb-3" style={{
                textAlign: props.button.style
            }}>
                <Link to={props.button.BTNUrl} className="btn button-md radius-xl">{props.button.BTNLib}</Link>
            </div>
        </>
    )
}