import React, { useState, useEffect } from 'react';
import Iframe from 'react-iframe';
import axios from "axios";
import config from '../../../config.json';

// Layout
import Header from "../../layout/header/header";
import Footer from "../../layout/footer/footer3";

// Components
import Banner from '../../elements/banner/banner-1';
import Modal from '../../elements/modal/modal-1';
import SEO from '../../elements/seo/seo';
import Text1 from '../../elements/text/text-1';
import ContactDetails from '../../elements/contact/contact-details';

// Hook
// import UseFetch from '../../Hooks/useFetch';


export default function Contact() {

	const [meta, setMeta] = useState('')
	const [banner, setBanner] = useState('')
	const [sec1, setSec1] = useState('')
	const [sec2, setSec2] = useState('')
	const [err, setErr] = useState(false) // Manage static data 
	const [cp1, setCp1] = useState('') 	// Localisation
	const [cp2, setCp2] = useState('') 	// Email
	const [cp3, setCp3] = useState('') 	// Phone

	// Social media
	const [cp4, setCp4] = useState('')
	const [cp5, setCp5] = useState('')
	const [cp6, setCp6] = useState('')
	const [cp7, setCp7] = useState('')

	const [map, setMap] = useState('') // Map


	// CONFIG MODAL FORM CONTACT -------------------------------------->
	const [openModal, setOpenModal] = useState(false);

	const design = {
		withModal: '380px',
		heightModal: '273px',
		sizeButton: '37%',
		sizeLogo: '50%',
		crossToClose: 'X'

	};
	const text = {
		p: "Votre message a bien été envoyé !",
		textButton: "Ok"
	};

	// DATA FORM ------------------------------------------------------>
	const [dataPost, setDataPost] = useState({
		nom: "",
		prenom: "",
		email: "",
		telephone: "",
		message: ""
	})

	// Nettoyer les champs
	const clearState = () => {
		setDataPost({
			nom: "",
			prenom: "",
			email: "",
			telephone: "",
			message: ""
		})
	};

	const handleChange = (e) => {
		const { id, value } = e.target;

		// Vérification pour le champ de téléphone
		if (id === 'telephone') {
			const numericValue = value.replace(/\D/g, ''); // Supprimer tous les caractères non numériques
			setDataPost((prevState) => ({
				...prevState,
				[id]: numericValue
			}));
		} else {
			setDataPost((prevState) => ({
				...prevState,
				[id]: value
			}));
		}
	};

	// Soumission formulaire	
	const handleSubmit = (e) => {
		e.preventDefault();

		axios.post(`${config.urlServer}api/formdata`, {
			name: dataPost.nom,
			firstname: dataPost.prenom,
			email: dataPost.email,
			phone: dataPost.telephone,
			message: dataPost.message
		})
			.then((res) => {
				setOpenModal(true);
				clearState();
				// console.log(res);
			})
			.catch((err) => {
				// console.log(err);
			});
	};

	// Soumission formulaire avec hook -- MARCHE PAS
	// const handleSubmit = (e) => {
	// 	e.preventDefault();

	// 	const { data, error } = UseFetch('/formdata', 'POST', {
	// 		name: dataPost.nom,
	// 		firstname: dataPost.prenom,
	// 		email: dataPost.email,
	// 		phone: dataPost.telephone,
	// 		message: dataPost.message,
	// 	});

	// 	if (data) {
	// 		setOpenModal(true);
	// 		clearState();
	// 	}

	// 	if (error) {
	// 		console.log(error);
	// 	}
	// };


	// Administration data
	useEffect(() => {
		axios.get(`${config.urlServer}api/contact`)
			.then(function (res) {
				setMeta(res.data)
				setBanner(res.data.Composents[0])
				setSec1(res.data.Composents[1])
				setSec2(res.data.Composents[4])

				// Localisation
				setCp1(res.data.Composents[5].Data)

				// Email
				setCp2(res.data.Composents[1].Data[0])

				// Phone
				setCp3(res.data.Composents[1].Data[1])

				// Social media
				setCp4(res.data.Composents[2].Data[0].Corps)
				setCp5(res.data.Composents[2].Data[1].Corps)
				setCp6(res.data.Composents[2].Data[2].Corps)
				setCp7(res.data.Composents[2].Data[3].Corps)

				// Map 
				setMap(res.data.Composents[3].BTNUrl)
			})
			.catch(err => {
				setErr(true)
			})
	}, []);// spécifie une liste de dépendances vide pour indiquer à React que l'effect ne dois pas être executé à l'infini


	// CONST 
	const metaData = {
		title: !meta.TitreMeta ? "Contactez Yogan Développement" : meta.TitreMeta,
		description: !meta.description ? "Contactez notre équipe de développeurs dès à présent au 04 65 23 00 00 ou passer directement à nos bureaux." : meta.description,
		canonical: !meta.URLCanonical ? "contact" : meta.URLCanonical
	}

	const bannerContent = {
		title: !banner.Titre ? "Contact" : banner.Titre,
		entitled: !banner.Corps ? "Contact" : banner.Corps
	}

	const contentContactDetails = {
		localisation: cp1,
		emailLib: !cp2.BTNLib ? "contact@yogan.fr" : cp2.BTNLib,
		emailUrl: !cp2.BTNUrl ? "mailto:contact@yogan.fr" : cp2.BTNUrl,
		telLib: !cp3.BTNLib ? "04 65 23 00 00" : cp3.BTNLib,
		telUrl: !cp3.BTNUrl ? "tel:0465230000" : cp3.BTNUrl,
		facebook: !cp4 ? "https://www.facebook.com/yogansoftware/" : cp4,
		twitter: !cp5 ? "https://mobile.twitter.com/yogansoftware" : cp5,
		linkedin: !cp6 ? "https://es.linkedin.com/company/yogan" : cp6,
		youtube: !cp7 ? "https://www.youtube.com/channel/UC2qrLXlbOajb6NRCdhBZlxg/videos?app=desktop&cbrd=1" : cp7
	}

	// STATIC LOCALISATION ENTREPRISE

	const staticContent = [
		{
			localisation: "270 Rue François Gernelle, 84120 Pertuis",
		},
		{
			localisation: "4 Avenue de l'Opéra, 75001 Paris"
		}
	]

	const mapIframe = {
		src: !map ? "https://www.google.com/maps/embed/v1/place?q=place_id:ChIJkVZu1GonyhIR-WTzLfmOuC0&key=AIzaSyC_mgwWgX9362d4E5OttZc6JrL0XO5KAwE" : map
	}

	const text1 = {
		title: !sec1.Titre ? "Nos coordonnées" : sec1.Titre,
		h: "capitalize"
	}
	const text2 = {
		title: !sec2.Titre ? "Nous contacter" : sec2.Titre,
		p: !sec2.Corps ? "Utilisez le formulaire ci-dessous pour nous contacter (Veillez à utiliser votre mail client) :" : sec2.Corps,
		h: "capitalize",
		psize: "18px"
	}

	return (
		<>
			{/* Menu */}
			<Header />

			{/* Dynamic Meta Tags */}
			<SEO
				title={metaData.title}
				description={metaData.description}
				canonical={metaData.canonical}
			/>

			<Banner
				title={bannerContent.title}
				entitled={bannerContent.entitled}
				navTitle="Accueil"
				navLink="/"
			/>

			{/* Si modal = true alors l'ouvrir */}
			{openModal &&
				<Modal
					setOpenModal={setOpenModal}
					design={design}
					text={text}
				/>}


			{/* <!-- Nos coordonnées Start --> */}
			<div className="section-area section-sp1" id="content-area">
				<div className="container">
					<Text1
						text={text1}
					/>

					<ContactDetails
						content={contentContactDetails}
						static={staticContent}
						err={err}
					/>
				</div>
			</div>
			{/* <!-- Nos coordonnées End --> */}


			{/* <!-- Map Start --> */}
			<Iframe
				src={mapIframe.src}
				className="align-self-stretch map-frame d-flex"
				allowfullscreen
			/>
			{/* <!-- Map End --> */}


			{/* <!-- Nous contacter Start --> */}
			<div className="section-area section-sp2">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 wow fadeIn" data-wow-delay="0.4s">
							<form className="contact-bx ajax-form" onSubmit={(e) => handleSubmit(e)}>
								<div className="ajax-message"></div>
								<Text1 text={text2} />
								<div className="row placeani">
									<div className="col-lg-3">
										<div className="form-group">
											<div className="input-group">
												<input
													id="nom"
													type="text"
													className="form-control valid-character"
													placeholder="Votre Nom"
													value={dataPost.nom}
													onChange={e => handleChange(e)}
													required
													pattern="[A-Za-zÀ-ÖØ-öø-ÿ]*" // Seuls les caractères alphabétiques (y compris les accents) sont autorisés
													title="Veuillez saisir uniquement des lettres."
												/>
											</div>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="form-group">
											<div className="input-group">
												<input
													id="prenom"
													type="text"
													className="form-control valid-character"
													placeholder="Votre Prénom"
													value={dataPost.prenom}
													onChange={e => handleChange(e)}
													required
													pattern="[A-Za-zÀ-ÖØ-öø-ÿ]*" // Seuls les caractères alphabétiques (y compris les accents) sont autorisés
													title="Veuillez saisir uniquement des lettres."
												/>
											</div>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="form-group">
											<div className="input-group">
												<input
													id="email"
													type="email"
													className="form-control"
													placeholder="Votre Email"
													value={dataPost.email}
													onChange={e => handleChange(e)}
													required
												/>
											</div>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="form-group">
											<div className="input-group">
												<input
													id="telephone"
													type="text"
													size='11'
													maxLength='10' // Limite à 10 caractères
													inputMode='numeric'
													className="form-control int-value"
													placeholder="Votre Téléphone"
													value={dataPost.telephone}
													onChange={e => handleChange(e)}
													required
													pattern=".{10,}" // Au moins 10 caractères
													title="Veuillez saisir au moins 10 chiffres."
												/>
											</div>
										</div>
									</div>
									<div className="col-lg-12">
										<div className="form-group">
											<div className="input-group">
												<textarea
													id="message"
													rows="4"
													className="form-control"
													placeholder="Votre Demande"
													value={dataPost.message}
													onChange={e => handleChange(e)}
													required
												/>
											</div>
										</div>
									</div>
									<div className="col-lg-12 mb-5">
										<button name="submit" type="submit" value="Submit" className="btn button-md">Envoyer</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			{/* <!-- Nous contacter End --> */}

			<Footer />

		</>
	);
}

