import React from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header from "../../layout/header/header";
import Footer from "../../layout/footer/footer3";

// Element
import Banner from '../../elements/banner/banner-2';
import SEO from '../../elements/seo/seo';
import LogoCarouselPartners from "../../elements/client-logo/logo-carousel-partners";


export default function Partners() {

	// CONST
	const metaData = {
		title: "La force d'un réseau de partenaires",
		description: "Yogan développement développe un réseau de partenaire pour un assemblage de compétences au plus haut niveau.",
		canonical: "partenaires"
	}

	const bannerContent = {
		title: "Nos partenaires",
		entitled: "Nos partenaires"
	}

	return (
		<>
			{/* Dynamic Meta Tags */}
			<SEO
				title={metaData.title}
				description={metaData.description}
				canonical={metaData.canonical}
			/>

			<Header />

			<Banner title={bannerContent.title} entitled={bannerContent.entitled} navTitle="Accueil" navLink="/" />
			
			<div className="content-block" id="content-area">
				<div className="section-area section-sp2 team-page bg-gray">
					<div className="container mb-4">
						<h2 className='h3'>Des savoir-faire et spécialistes reconnus</h2>
						<blockquote className='bg-gray'>
							<span>
								Les meilleurs services sont offerts par des spécialistes qui sont des experts dans leur domaine et dont la maîtrise et la connaissance sont axées sur
								les besoins spécifiques de notre clientèle. Pour vous offrir <Link to="/logiciels">des solutions sur mesure</Link>, nous avons sélectionné des établissements reconnus pour leurs
								compétences et leur savoir-faire.
								Nous avons également tenu compte des compétences des équipes ainsi que de la notoriété des enseignes pour garantir une prestation humaine de qualité.
							</span>
						</blockquote>

						<div style={{
							marginTop: "8%",
							marginBottom: "7%"
						}}>
							<LogoCarouselPartners />
						</div>

						<div className='text-start'>
							<Link to="/contact" className='feature-btn'>En savoir plus</Link>
						</div>
					</div>
				</div>

			</div>
			<Footer />
		</>
	);
}