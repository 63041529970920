import React, { useState, useCallback, useEffect } from 'react';
import { Link } from "react-router-dom";
import ImageViewer from 'react-simple-image-viewer';

// Get Api
import axios from "axios";
import config from "../../../config.json";

// Markdown 
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";  // allows to use html tag in markdown

// Layout
import Header from "../../layout/header/header";
import Footer from "../../layout/footer/footer3";

//Elements
import Banner from '../../elements/banner/banner-2';
import SEO from '../../elements/seo/seo';

// Images
import pic1 from '../../../images/infinity/infinity-1.webp';
import pic2 from '../../../images/infinity/infinity-2.webp';

export default function AppMobile() {

    // SETTER 
    const [meta, setMeta] = useState('') // MetaData
    const [banner, setBanner] = useState('')

    // Page section 
    const [section1, setSection1] = useState('') // Intro
    const [section2, setSection2] = useState('') // Gérer votre entreprise
    const [section3, setSection3] = useState('') // Nos solutions existantes
    const [section4, setSection4] = useState('') // Mise en oeuvre


    // List 
    const [list1, setList1] = useState('') // solutions existantes list 1
    const [list2, setList2] = useState('') // solutions existantes list 2


    // Carousel
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    // Manage static data 
    const [err, setErr] = useState(false)


    useEffect(() => {
        // get back data from express
        axios.get(`${config.urlServer}api/app-mobile`)
            .then(function (res) {
                setMeta(res.data) // metadata from webservice
                setBanner(res.data.Composents[0]) // data banner
                // section
                setSection1(res.data.Composents[1])
                setSection2(res.data.Composents[2])
                setSection3(res.data.Composents[3])
                setSection4(res.data.Composents[5])

                // list
                setList1(res.data.Composents[3].Data[0].Corps)
                setList2(res.data.Composents[3].Data[1].Corps)
                // console.log(res.data.Composents[4].Data) gestion image dans carousel impossible pour l'instant besoin d'un id unique voir avec le backo

            })
            .catch(err => {
                setErr(true)
            })
    }, []);


    //CONST CAROUSEL 
    const images = [
        pic1,
        pic2
    ];

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };


    return (
        <>
            {/* Menu */}
            <Header />

            {/* Dynamic Meta Tags */}
            <SEO
                title={!meta.TitreMeta ? "Application Mobile métier WinDev" : meta.TitreMeta}
                description={!meta.description ? "Application mobile métier WinDev Mobile sur mesure pour les professionnels et les secteurs métiers ciblés." : meta.description}
                canonical={!meta.URLCanonical ? "application-mobile-metier" : meta.URLCanonical}
            />

            <Banner title={!banner.Titre ? "Mobile" : banner.Titre} entitled={!banner.Corps ? "Application mobile" : banner.Corps} navTitle="Accueil" navLink="/" />

            <div className="content-block" id="content-area">
                <div className="section-area section-sp1 bg-gray">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-6 mb-4">

                                {/* <!-- Intro Start --> */}
                                <h2 className="text-capitalize">{!section1.Titre ? "Votre application sur mesure" : section1.Titre}</h2>
                                <ReactMarkdown>{section1.Corps}</ReactMarkdown>
                                {
                                    // if you don't receive any data then show me this
                                    // err = true
                                    err &&
                                    <p>
                                        Nous <span className="fw-bold">concevons</span>, <span className="fw-bold">développons</span> et <span className="fw-bold">maintenons</span> des applications mobiles performantes
                                        qui peuvent répondre à des problématiques business et techniques complexes.
                                    </p>
                                }
                                {/* <!-- Intro End --> */}


                                {/* <!-- Gérer votre entreprise Start --> */}
                                <h3 className="text-capitalize mt-5">{!section2.Titre ? "Gérer votre entreprise depuis votre smartphone !" : section2.Titre}</h3>
                                {section2.Data &&
                                    section2.Data.map((item) => (
                                        <div key={item.ID}>
                                            <ReactMarkdown children={item.Titre} rehypePlugins={[rehypeRaw]} />
                                            <p>{item.Corps}</p>
                                        </div>
                                    ))}

                                {err &&
                                    <div>
                                        <div>
                                            <h4>✓<span style={{ color: '#ff5622' }}> Accès</span> pour tous</h4>
                                            <p>
                                                Faites en sorte que votre outil métier soit accessible à l'ensemble de vos collaborateurs,
                                                quelle que soit leur situation géographique ou technique.
                                            </p>
                                        </div>
                                        <div>
                                            <h4>✓ Travailler <span style={{ color: '#ff5622' }}>d'ou vous voulez !</span></h4>
                                            <p>
                                                Vos applications métier sont accessibles partout, y compris dans les zones où vous n’avez pas de connexion internet.
                                                Nos outils peuvent travailler hors connexion et basculer aisément d’une connexion wifi à mobile.
                                            </p>
                                        </div>
                                    </div>
                                }

                                <ReactMarkdown>{section2.Corps}</ReactMarkdown>
                                {
                                    err && // if err = true then you display the static 
                                    <div>
                                        <h4 className="mt-4">Les principaux atouts de votre application mobile :</h4>
                                        <ul style={{ paddingLeft: "27px" }}>
                                            <li>Augmenter votre productivité avec un outil conçu pour votre secteur métier</li>
                                            <li>Informations des systèmes d’informations en temps réel</li>
                                            <li>Optimiser la gestion de vos projets et de vos équipes</li>
                                            <li>Faciliter la gestion de votre entreprise</li>
                                            <li>Gain de temps et d’efficacité</li>
                                            <li>Fonctionnelle hors réseau</li>
                                        </ul>
                                    </div>
                                }
                                {/* <!-- Gérer votre entreprise End --> */}


                                {/* <!-- Nos solutions existantes Start --> */}
                                <br />
                                <div className="mt-2">
                                    <h3 className="text-capitalize">{!section3.Titre ? "Nos solutions existantes" : section3.Titre}</h3>
                                    <ReactMarkdown children={section3.Corps} rehypePlugins={[rehypeRaw]} />

                                    {err &&
                                        <div>
                                            <h4 style={{ color: '#ff5622' }}><a href="https://play.google.com/store/apps/details?id=fr.yogan.infinity" target="_blank" rel="noreferrer">Infinity 📱</a></h4>
                                            <p className="fw-bold">
                                                Application de suivi d’intervention pour les artisans. Adapter à divers métiers comprenant des interventions.
                                            </p>
                                            <p>
                                                S’adressent à tous les artisans qui devront gérer plusieurs
                                                salariés fessant des interventions en clientèle et devant suivre
                                                ces interventions pour faire des comptes rendus pour les assureurs
                                                ou à tout autre maitre d’œuvre.
                                            </p>
                                            <p>
                                                Cela en fait l’outil idéal pour un artisan avec des interventions comprenant plusieurs intervenants.
                                            </p>
                                        </div>
                                    }

                                </div>
                                <div>
                                    {
                                        err &&
                                        <p className="fw-bold">Liste non-exaustive des fonctionnalités : </p>
                                    }

                                    <div className="mt-4 mb-4">
                                        <div className="row">
                                            <div className="col-6">
                                                <ul className="list-group" style={{ paddingLeft: "27px" }}>
                                                    <ReactMarkdown>{list1}</ReactMarkdown>
                                                </ul>
                                                {err &&
                                                    <ul className="list-group" style={{ paddingLeft: "27px" }}>
                                                        <div>

                                                            <li className="puce-change">Affichage des interventions en cours ou à traiter dans la journée</li>
                                                            <li className="puce-change">Suivi des interventions sur tablette ou mobile en temps réel</li>
                                                            <li className="puce-change">Modification, suppression et ajout d’une intervention</li>
                                                            <li className="puce-change">Photos du produit client avant et après pose</li>
                                                            <li className="puce-change">Signalement de la fin d’une intervention</li>
                                                        </div>
                                                    </ul>
                                                }
                                            </div>
                                            <div className="col-6">
                                                <ul className="list-group" style={{ paddingLeft: "40px" }}>
                                                    <ReactMarkdown>{list2}</ReactMarkdown>
                                                </ul>
                                                {err &&
                                                    <ul className="list-group" style={{ paddingLeft: "40px" }}>
                                                        <li className="puce-change">Présentation des articles et des stocks</li>
                                                        <li className="puce-change">Gestion des collaborateurs</li>
                                                        <li className="puce-change">Gestions des facturations</li>
                                                        <li className="puce-change">Plannings des techniciens</li>
                                                        <li className="puce-change">Suivi des dossiers clients</li>
                                                    </ul>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {images.map((src, index) => (
                                            <img
                                                src={src}
                                                onClick={() => openImageViewer(index)}
                                                width="500"
                                                key={index}
                                                style={{ margin: '2px', height: "100%", paddingTop: '8px' }}
                                                alt="Yogan développement application mobile infinity"
                                            />
                                        ))}

                                        {isViewerOpen && (
                                            <ImageViewer
                                                src={images}
                                                currentIndex={currentImage}
                                                disableScroll={false}
                                                closeOnClickOutside={true}
                                                onClose={closeImageViewer}
                                            />
                                        )}
                                    </div>
                                </div>
                                {/* <!-- Nos solutions existantes End --> */}


                                {/* <!-- Mise en oeuvre Start --> */}
                                <div className='mt-5'>
                                    <h4>{!section4.Titre ? "Faciles à mettre en oeuvre et opérationnels de suite" : section4.Titre}</h4>
                                    <ReactMarkdown>{section4.Corps}</ReactMarkdown>

                                    {err &&
                                        <div>
                                            <p>
                                                Chacune de nos applications mobiles métiers est développée sur mesure selon les besoins
                                                et les objectifs de l’entreprise utilisatrice (gestions administratives, gestion du personnel,
                                                acquisition de client, etc…).
                                            </p>
                                            <p>
                                                Faire développer avec WINDEV Mobile son application, c’est la sécurité d’utiliser des environnements
                                                qui permettent d’atteindre de façon certaine et rapide vos objectifs d’entreprise.
                                            </p>
                                            <p>
                                                L’application Mobile peut également faire directement suite à votre backoffice existant WinDev pour
                                                renforcer la gestion de votre entreprise.
                                            </p>
                                            <p>
                                                Notre objectif premier est d’améliorer la productivité de vos collaborateurs et de votre entreprise
                                                avec un outil spécialement conçu pour répondre aux besoins spécifiques de votre secteur métier.
                                            </p>
                                            <p>
                                                Demandez <span className="fw-bold">gratuitement</span> un devis en nous adressant une demande via nos <Link to='/contact'>formulaires de contact</Link> en nous
                                                laissant vos coordonnées d’entreprise ou contactez-nous directement par téléphone au <a href="tel:0465230000" className="link-hover-primary">04 65 23 00 00</a>.
                                                Notre équipe fera suite à votre demande dans les plus brefs délais.
                                            </p>
                                        </div>
                                    }
                                </div>
                                <div className="text-center mt-5 mb-5">
                                    <Link to={!section4.BTNUrl ? "/contact" : section4.BTNUrl} className="btn button-md">{!section4.BTNLib ? "Demander une démo" : section4.BTNLib}</Link>
                                </div>
                                {/* <!-- Mise en oeuvre End --> */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}