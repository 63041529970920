import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import { Link } from "react-router-dom";
import axios from "axios";
import config from "../../../config.json";

export default function PortfolioSlider3(props) {

	const settings = {
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1280,
				settings: {
					slidesToShow: 3,
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
				}
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 591,
				settings: {
					slidesToShow: 1,
				}
			}
		]
	};

	// SETTER 
	const [card1, setCard1] = useState('')
	const [card2, setCard2] = useState('')
	const [card3, setCard3] = useState('')
	const [card4, setCard4] = useState('')

	useEffect(() => {
		axios.get(`${config.urlServer}api/accueil`)
			.then(function (res) {
				setCard1(res.data.Composents[4].Data[0])
				setCard2(res.data.Composents[4].Data[1])
				setCard3(res.data.Composents[4].Data[2])
				setCard4(res.data.Composents[4].Data[3])
			})
	},[]);

	// CONST 
	const section = {
		title: !props.sec3.Titre ? "Nos solutions" : props.sec3.Titre,
		p: !props.sec3.Corps ? "Selon vos exigences et contraintes fonctionnelles, plusieurs types de développements peuvent être proposés avec différentes technologies." : props.sec3.Corps
	}

	const content = [
		{
			flaticon: "flaticon-networking",
			title: !card1.Titre ? "Logiciels Métier" : card1.Titre,
			exeTitle: !card1.Corps ? "back office" : card1.Corps,
			url: !card1.BTNUrl ? "/logiciels" : card1.BTNUrl
		},
		{
			flaticon: "flaticon-computer",
			title: !card2.Titre ? "Site Web" : card2.Titre,
			exeTitle: !card2.Corps ? "Fron end" : card2.Corps,
			url: !card2.BTNUrl ? "/sites" : card2.BTNUrl
		},
		{
			flaticon: "flaticon-networking-1",
			title: !card3.Titre ? "Connecteurs d'API" : card3.Titre,
			exeTitle: !card3.Corps ? "Communication" : card3.Corps,
			url: !card3.BTNUrl ? "/connecteurs-api" : card3.BTNUrl
		},
		{
			flaticon: "flaticon-smartphone",
			title: !card4.Titre ? "App Mobile" : card4.Titre,
			exeTitle: !card4.Corps ? "Front office" : card4.Corps,
			url: "/application-mobile-metier"
		}
		// boite de card avec défilement possible
	]

	return (
		<>
			<div className="content-block" id="content-area">
				<div className="section-area section-sp2 bg-gray">
					<div className="section-area">
						<div className="container-sm">
							<div className="container-max wow fadeIn" data-wow-delay="0.4s">
								<div className="heading-bx text-center">
									<h2 className="title-head m-b0">{section.title}</h2>
									<p className="m-b0">{section.p}</p>
								</div>
								<Slider {...settings} className="portfolio-carousel-3 style-3 slider-sp15 owl-btn-1 owl-btn-center-lr">
									{content.map((item, index) => (
										<Link to={item.url}>
											<div className="slider-item" key={index}>
												<div className="portfolio-box style-1 m-b0">
													<div className="portfolio-media" style={{
														display: "flex",
														alignItems: "center",
														justifyContent: "center",
														fontSize: "130px",
														background: "#f9f9f9"
													}}>
														<i className={item.flaticon} style={{
															color: "var(--primary)"
														}}></i>
													</div>
													<div className="portfolio-info" style={{
														borderTop: "1.9px solid #d9d9d9"
													}}>
														<h4 className="title text-capitalize"><Link to={item.url}>{item.title}</Link></h4>
														<span className="exe-title">{item.exeTitle}</span>
													</div>
												</div>
											</div>
										</Link>
									))}
								</Slider>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}


