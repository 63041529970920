import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Images
import PortImg10 from './../../../images/references/logo-haussmann.webp';
import PortImg12 from './../../../images/references/logo-kandella.webp';
import PortImg13 from './../../../images/references/logo-ocacia-2.webp';

// const FilterList = ({ dataFilter, defaultTag, activeFilter }) => {
// 	return (
// 		<li className={`${activeFilter ? 'btn active' : 'btn'}`} onClick={() => defaultTag(dataFilter)} >
// 			<a href="#">{dataFilter}</a>
// 		</li>
// 	);
// };

function PortfolioContent(props) {
	const [tag, setTag] = useState('Show All');
	const [filteredImages, setFilterdImages] = useState([]);

	useEffect(() => {
		tag === 'Show All' ? setFilterdImages(content) : setFilterdImages(content.filter(image => image.tag.find(element => element === tag)))
	}, [tag])

	// Portfolio Content
	const content = [
		{
			thumb: PortImg10,
			title: !props.data1.Titre ? "Haussmann Patrimoine" : props.data1.Titre,
			exetitle: !props.data1.Corps ? "Cabinet conseil en gestion de patrimoine" : props.data1.Corps,
			alt: !props.data1.Titre ? "Haussmann Patrimoine Cabinet conseil en gestion de patrimoine" : props.data1.Titre,
			url: !props.data1.BTNUrl ? "#" : props.data1.BTNUrl,
			stylecard: "portfolio-media style-card"
		},
		{
			thumb: PortImg12,
			//système de tag possible pour trier
			// tag: ['design',],
			title: !props.data2.Titre ? "Kandella" : props.data2.Titre,
			exetitle: !props.data2.Corps ? "Tout l'univers du luminaire" : props.data2.Corps,
			alt: !props.data2.Titre ? "Kandella Tout l'univers du luminaire" : props.data2.Titre,
			url: !props.data2.BTNUrl ? "#" : props.data2.BTNUrl,
			stylecard: "portfolio-media style-card-type-2"
		},
		{
			thumb: PortImg13,
			title: !props.data3.Titre ? "Ocacia" : props.data3.Titre,
			exetitle: !props.data3.Corps ? "Organisme certificateur" : props.data3.Corps,
			alt: !props.data3.Titre ? "Ocacia Organisme certificateur" : props.data3.Titre,
			url: !props.data3.BTNUrl ? "#" : props.data3.BTNUrl,
			stylecard: "portfolio-media style-card-type-3"
		}
	]

	return (
		<>
			{/* <div className="feature-filters clearfix center m-b40">
				<ul className="filters" data-toggle="buttons">
					<FilterList 
						dataFilter="Show All" 
						defaultTag={setTag} 
						activeFilter={ tag === 'Show All' ? true : false }	
					/>
					<FilterList 
						dataFilter="design" 
						defaultTag={setTag} 
						activeFilter={ tag === 'design' ? true : false }
					/>
					<FilterList 
						dataFilter="seo" 
						defaultTag={setTag} 
						activeFilter={ tag === 'seo' ? true : false }
					/>
					<FilterList 
						dataFilter="development"
						defaultTag={setTag} 
						activeFilter={ tag === 'development' ? true : false }	
					/>
				</ul>
			</div> */}
			<div className="row sp20">
				{filteredImages.map((item, index) => (
					<div className="action-card col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
						<div className="portfolio-box style-2 style-card-white">
							<div className={item.stylecard}>
								<img src={item.thumb} alt={item.alt} />
							</div>
							<div className="portfolio-info">
								<h4 className="title"><Link to={item.url}>{item.title}</Link></h4>
								<span className="exe-title">{item.exetitle}</span>
							</div>
						</div>
					</div>
				))}
			</div>
		</>
	);
}

export default function PortMasonry(props) {
	return (
		<>
			<PortfolioContent data1={props.data1} data2={props.data2} data3={props.data3} />
		</>
	)
}
