import React, { useState, useEffect } from 'react';
import axios from "axios";
import config from "../../../config.json";

// Layout
import Header from '../../layout/header/header';
import Footer from '../../layout/footer/footer3';

// Element
import Banner from '../../elements/banner/banner-1';
import SEO from '../../elements/seo/seo';

// Text
import Text1 from '../../elements/text/text-1';

// Card
import Card2 from '../../elements/card/card-2';

// Buttun 
import Button1 from '../../elements/button/button-1';

export default function Support() {

	// SETTER 
	const [meta, setMeta] = useState('')
	const [banner, setBanner] = useState('')
	const [data, setData] = useState('')
	const [card1, setCard1] = useState('')
	const [card2, setCard2] = useState('')
	const [card3, setCard3] = useState('')
	const [card4, setCard4] = useState('')

	useEffect(() => {
		axios.get(`${config.urlServer}api/support`)
			.then(function (res) {
				setMeta(res.data)
				setBanner(res.data.Composents[0])
				setData(res.data.Composents[1])
				setCard1(res.data.Composents[1].Data[0])
				setCard2(res.data.Composents[1].Data[1])
				setCard3(res.data.Composents[1].Data[2])
				setCard4(res.data.Composents[1].Data[3])
			})
	},[]);

	// CONST 
	const metaData = {
		title: !meta.TitreMeta ? "Service d'assistance sur mesure" : meta.TitreMeta,
		description: !meta.description ? "Service support pour les clients de Yogan Développement, 7j/7." : meta.description,
		canonical: !meta.URLCanonical ? "support" : meta.URLCanonical
	}

	const bannerContent = {
		title: !banner.Titre ? "Support" : banner.Titre,
		entitled: !banner.Corps ? "Support" : banner.Corps
	}

	const button = {
		BTNLib: !data.BTNLib ? "demander le service" : data.BTNLib,
		BTNUrl: !data.BTNUrl ? "/contact" : data.BTNUrl,
		style: "center"
	}

	const text = {
		title: !data.Titre ? "Service D'assistance" : data.Titre,
		p: !data.Corps ? "Service support disponible pour nos clients." : data.Corps,
		alignText: 'center',
		h: "capitalize"
	}

	return (
		<>
			{/* Menu */}
			<Header />

			{/* Dynamic Meta Tags */}
			<SEO
				title={"Service d'assistance sur mesure"}
				description={metaData.description}
				canonical={metaData.canonical}
			/>


			<Banner title={bannerContent.title} entitled={bannerContent.entitled} navTitle="Accueil" navLink="/" />

			{/* <!-- Service d'assistance Start --> */}
			<div className="section-area section-sp2" id="content-area">
				<div className="container">
					<Text1 text={text} /><br />
					<Card2 card1={card1} card2={card2} card3={card3} card4={card4} />
					<Button1 button={button} />
				</div>
			</div>
			{/* <!-- Service d'assistance End --> */}

			<Footer />
		</>
	);
}

