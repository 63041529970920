import React, { useState, useEffect } from 'react';

// Get Api
import axios from "axios";
import config from "../../../config.json";

// Markdown 
import ReactMarkdown from "react-markdown";

// Layout
import Header from "../../layout/header/header";
import Footer from "../../layout/footer/footer3";

// Element
import Banner from '../../elements/banner/banner-2';
import Accordion from '../../elements/accordion/accordion-1';
import SEO from '../../elements/seo/seo';

export default function Recrutement(props) {

	// SETTER 
	const [meta, setMeta] = useState('')
	const [banner, setBanner] = useState('')
	const [data, setData] = useState('')

	// Accordion 
	const [accordeon, setAccordeon] = useState('')

	useEffect(() => {
		axios.get(`${config.urlServer}api/recrutement`)
			.then(function (res) {
				setMeta(res.data)
				setBanner(res.data.Composents[0])
				setData(res.data.Composents[1])

				// Accordeon
				setAccordeon(res.data.Composents[2].Data)
			})
	}, []);

	// CONST
	const metaData = {
		title: !meta.TitreMeta ? "Recrutement : offres d'emploi, CDI, stages, alternances" : meta.TitreMeta,
		description: !meta.description ? "Vous êtes un développeur backend ou frontend et vous souhaitez nous rejoindre ? Postulez dès à présent." : meta.description,
		canonical: !meta.URLCanonical ? "recrutement" : meta.URLCanonical
	}

	const bannerContent = {
		title: !banner.Titre ? "Recrutement" : banner.Titre,
		entitled: !banner.Corps ? "Recrutement" : banner.Corps
	}

	const content = {
		t: !data.Titre ? "Nos offres d’emploi" : data.Titre,
		corps: !data.Corps ? "Yogan Développement agrandit son équipe. Découvrez dès à présent nos différentes offres." : data.Corps,
	}

	return (
		<>
			{/* Menu */}
			<Header />

			{/* Dynamic Meta Tags */}
			<SEO
				title={metaData.title}
				description={metaData.description}
				canonical={metaData.canonical}
			/>

			<Banner title={bannerContent.title} entitled={bannerContent.entitled} navTitle="Accueil" navLink="/" />

			<div className="content-block" id="content-area">
				<div className="section-area section-sp2 team-page bg-gray">
					<div className="container">

						{/* <!-- Nos offres d'emploi Start --> */}
						<h2 className='text-center text-capitalize'>{content.t}</h2>
						<ReactMarkdown className='text-center mb-5'>{content.corps}</ReactMarkdown>
						{/* <!-- Nos offres d'emploi End --> */}


						{/* <!-- Accordéons Start --> */}
						<div className="row">
							<div className="col-lg-12 col-md-6 col-sm-12 mt-5 mb-5">
								<Accordion accordeon={accordeon} />
							</div>
						</div>
						{/* <!-- Accordéons End --> */}

					</div>
				</div>
			</div>

			<Footer />
		</>
	);
}