import React from "react";

export default function List1(props) {
    return (
        <>
            <div>
                <h3 className="text-capitalize">{props.title}</h3>
                <div className="ms-3 mb-5">
                    {props.list &&
                        props.list.map((item) => (
                            <ui key={item.ID}>
                                <li className="puce-change"><p className="competence_titre text-uppercase">{item.Titre}</p></li>
                                <p>{item.Corps}</p>
                            </ui>
                        ))}
                    {
                        props.err &&
                        props.static.map((item, index) => (
                            <ui key={index}>
                                <li className="puce-change"><p className="competence_titre text-uppercase">{item.title}</p></li>
                                <p>{item.corps}</p>
                            </ui>
                        ))
                    }

                </div>
            </div>
        </>
    )
} 
