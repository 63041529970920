import React, { useState, useEffect } from "react";

// Get Api
import axios from "axios";
import config from "../../../config.json";

// Markdown 
import ReactMarkdown from "react-markdown";

// Layout
import Header from "../../layout/header/header";
import Footer from "../../layout/footer/footer3";

// Element
import Banner from '../../elements/banner/banner-1';
import SEO from '../../elements/seo/seo';

// List 
import List4 from '../../elements/list/list-4';

// Title 
import Title1 from '../../elements/title/title-1';

export default function PlanSite() {

    // SETTER 
    const [meta, setMeta] = useState('')
    const [banner, setBanner] = useState('')
    const [data, setData] = useState('')

    // Manage static data 
    const [err, setErr] = useState(false)

    // List 
    const [dataList, setDataList] = useState([])

    useEffect(() => {
        axios.get(`${config.urlServer}api/plan-site`)
            .then(function (res) {
                setMeta(res.data)
                setBanner(res.data.Composents[0])
                setData(res.data.Composents[1])
                setDataList(res.data.Composents[2].Data)
            })
            .catch(err => {
                setErr(true)
            })
    },[]);

    // CONST 
    const metaData = {
        title: !meta.TitreMeta ? "Yogan Développement - Plan de site" : meta.TitreMeta,
        description: !meta.Description ? "Plan du site internet de la société Yogan Développement pour vous accompagner dans la navigation du site." : meta.Description,
        canonical: !meta.URLCanonical ? "plan-de-site" : meta.URLCanonical
    }

    const bannerContent = {
        title: !banner.Titre ? "Plan du site" : banner.Titre,
        entitled: !banner.Corps ? "Plan site" : banner.Corps
    }

    const content = {
        title: !data.Titre ? "Plan du site" : data.Titre,
        corps: !data.Corps ? "Découvrez le plan de site de Yogan Développement pour vous accompagner dans la navigation du site." : data.Corps
    }

    // STATIC DATA
    const listStatic = [
        {
            title: "Compétences web",
            corps: "https://yogan.fr/web",
            BTNUrl: "/web"
        },
        {
            title: "Compétences logiciels",
            corps: "https://yogan.fr/logiciels",
            BTNUrl: "/logiciels"
        },
        {
            title: "Compétences mobiles",
            corps: "https://yogan.fr/application-mobile-metier",
            BTNUrl: "/application-mobile-metier"
        },
        {
            title: "Support",
            corps: "https://yogan.fr/support",
            BTNUrl: "/support"
        },
        {
            title: "Sécurité",
            corps: "https://yogan.fr/securite",
            BTNUrl: "/securite"
        },
        {
            title: "Nos réalisaitons",
            corps: "https://yogan.fr/sites",
            BTNUrl: "/sites"
        },
        {
            title: "Nos références",
            corps: "https://yogan.fr/nos-references",
            BTNUrl: "/nos-references"
        },
        {
            title: "Notre équipe",
            corps: "https://yogan.fr/equipe-yogan-developpement",
            BTNUrl: "/equipe-yogan-developpement"
        },
        {
            title: "Nos Partenaires",
            corps: "https://yogan.fr/partenaires",
            BTNUrl: "/partenaires"
        },
        {
            title: "YogShow",
            corps: "https://www.yogshow.fr/",
            BTNUrl: "https://www.yogshow.fr/"
        },
        {
            title: "VieLocale",
            corps: "https://www.vielocale.fr/",
            BTNUrl: "https://www.vielocale.fr/"
        },
        {
            title: "yogPlanningIME",
            corps: "https://www.yogplanningime.fr/",
            BTNUrl: "https://www.yogplanningime.fr/"
        },
        {
            title: "Nos applications mobiles",
            corps: "https://play.google.com/store/apps/details?id=fr.yogan.infinity&pli=1",
            BTNUrl: "https://play.google.com/store/apps/details?id=fr.yogan.infinity&pli=1"
        },
        {
            title: "Modules WinDev",
            corps: "https://yogan.fr/connecteurs-api",
            BTNUrl: "/connecteurs-api"
        },
        {
            title: "Recrutement",
            corps: "https://yogan.fr/recrutement",
            BTNUrl: "/recrutement"
        },
        {
            title: "Nous contacter",
            corps: "https://yogan.fr/contact",
            BTNUrl: "/contact"
        },
        {
            title: "Mentions légales",
            corps: "https://yogan.fr/mentions-legales",
            BTNUrl: "/contact"
        },
        {
            title: "Politique de confidentialité",
            corps: "https://yogan.fr/politique-de-confidentialite",
            BTNUrl: "/politique-de-confidentialite"
        },
        {
            title: "Copyright",
            corps: "https://yogan.fr/copyright",
            BTNUrl: "/copyright"
        }
    ]

    return (
        <>
            {/* Menu */}
            <Header />

            {/* Dynamic Meta Tags */}
            <SEO
                title={metaData.title}
                description={metaData.description}
                canonical={metaData.canonical}
            />


            <Banner title={bannerContent.title} entitled={bannerContent.entitled} navTitle="Accueil" navLink="/" />


            {/* <!-- Plan du site Start --> */}
            <div className="section-area section-sp1" id="content-area">
                <div className="container mb-5">
                    <div className="row">
                        <div className="col-lg-12 col-md-6">
                            <Title1 title={content.title} textAlign={"center"} textDecoration={"underline"} />
                            <ReactMarkdown className='text-center mb-5'>{content.corps}</ReactMarkdown>
                            <div className="ml-18 mt-2">
                                <List4 list={dataList} static={listStatic} err={err} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Plan du site End --> */}


            <Footer />
        </>
    )
}