import React from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header from "../../layout/header/header";
import Footer from "../../layout/footer/footer3";

//Elements
import Banner from '../../elements/banner/banner-2';
import SEO from '../../elements/seo/seo';

export default function PlCMobile() {
    return (
        <>
            <Header />

            {/* Dynamic Meta Tags */}
            <SEO
                title="Politique de confidentialité: application Mobile"
                description="Politique de confidentialité application Mobile Yogan Développement"
                canonical="politique-de-confidentialite-mobile"
            />

            <Banner title="Politique de confidentialité" entitled="Application mobile" navTitle="Accueil" navLink="/" />
            
            <div className="content-block" id="content-area">
                <div className="section-area section-sp1 bg-gray">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-6 mb-4">
                                <h2 className="text-capitalize">
                                    <a href="#" target="_blank" rel="noreferrer"> Politique de confidentialité</a>
                                </h2>
                                <span className='fst-italic'>Dernière mise à jour: 27 avril 2023</span>
                            </div>
                            <p>
                                Cette politique de confidentialité décrit nos politiques et procédures sur la collecte,
                                l'utilisation et la divulgation de vos informations lorsque vous utilisez le service et
                                vous informe de vos droits à la confidentialité et de la manière dont la loi vous protège.
                            </p>
                            <p>
                                Nous utilisons vos données personnelles pour fournir et améliorer le service. En utilisant
                                le Service, vous acceptez la collecte et l'utilisation d'informations conformément à la
                                présente politique de confidentialité.
                            </p>
                            <h3>Interprétation et définitions</h3>
                            <h4>Interprétation</h4>
                            <p>
                                Les mots dont la lettre initiale est en majuscule ont des significations définies dans les conditions suivantes.
                                Les définitions suivantes ont la même signification, qu'elles apparaissent au singulier ou au pluriel.
                            </p>
                            <h4>Définitions</h4>
                            <p className='fw-bold'>Aux fins de cette politique de confidentialités :</p>
                            <p>
                                <span className='fw-bold' style={{
                                    color: "#ff5622"
                                }}>Compte</span> signifie un compte unique créé pour que vous puissiez accéder à notre service ou à des parties de notre service.

                            </p>
                            <p>
                                <span className='fw-bold' style={{
                                    color: "#ff5622"
                                }}>Affilié </span> désigne une entité qui contrôle, est contrôlée par ou est sous contrôle commun avec une partie, où «contrôle»
                                signifie la propriété de 50% ou plus des actions, participations ou autres titres habilités à voter pour l'élection des administrateurs ou d'une autre autorité de gestion.
                            </p>
                            <p>
                                <span className='fw-bold' style={{
                                    color: "#ff5622"
                                }}>Application  </span> désigne le logiciel fourni par la Société que Vous avez téléchargé sur tout appareil électronique.
                            </p>
                            <p>
                                <span className='fw-bold' style={{
                                    color: "#ff5622"
                                }}>Société </span> (dénommée «la Société», «Nous», «Notre» ou «Notre» dans le présent Contrat) fait référence à Yogan Développement, 4 Av. de l'Opéra 75001, Paris.
                            </p>
                            <p>
                                <span className='fw-bold' style={{
                                    color: "#ff5622"
                                }}>Appareil </span> désigne tout appareil pouvant accéder au Service tel qu'un ordinateur, un téléphone portable ou une tablette numérique.
                            </p>
                            <p>
                                <span className='fw-bold' style={{
                                    color: "#ff5622"
                                }}>Les données personnelles </span> sont toutes les informations relatives à une personne identifiée ou identifiable.
                            </p>
                            <p>
                                <span className='fw-bold' style={{
                                    color: "#ff5622"
                                }}>Le service </span> fait référence à l'application.
                            </p>
                            <p>
                                <span className='fw-bold' style={{
                                    color: "#ff5622"
                                }}>Prestataire de services </span> désigne toute personne physique ou morale qui traite les données pour le compte de la Société.
                                Il fait référence à des sociétés tierces ou à des personnes employées par la société pour faciliter le service, pour
                                fournir le service au nom de la société, pour fournir des services liés au service ou pour aider la société à analyser la façon
                                dont le service est utilisé.
                            </p>
                            <p>
                                <span className='fw-bold' style={{
                                    color: "#ff5622"
                                }}>Le service de médias sociaux tiers </span> fait référence à tout site Web ou à tout site Web de réseau social via lequel
                                un utilisateur peut se connecter ou créer un compte pour utiliser le service.
                            </p>
                            <p>
                                <span className='fw-bold' style={{
                                    color: "#ff5622"
                                }}>Les données d'utilisation font </span> référence aux données collectées automatiquement, soit générées par l'utilisation
                                du service, soit à partir de l'infrastructure du service elle-même (par exemple, la durée d'une visite de page).
                            </p>
                            <p>
                                <span className='fw-bold' style={{
                                    color: "#ff5622"
                                }}>Vous </span> désigne la personne qui accède ou utilise le Service, ou la société ou toute autre entité juridique
                                au nom de laquelle cette personne accède ou utilise le Service, selon le cas.
                            </p>
                            <h2>Collecte et utilisation de vos données personnelles</h2>
                            <h3>Types de données collectées</h3>
                            <h4>Données personnelles</h4>
                            <p>
                                Lors de l'utilisation de notre service, nous pouvons vous demander de nous fournir certaines informations personnellement
                                identifiables qui peuvent être utilisées pour vous contacter ou vous identifier. Les informations personnellement identifiables
                                peuvent inclure, mais sans s'y limiter :
                            </p>
                            <ui>
                                <li>Adresse e-mail</li>
                                <li>Des données d'utilisation</li>
                            </ui>
                            <h4 className='mt-3'>Des données d'utilisation</h4>
                            <p>Les données d'utilisation sont collectées automatiquement lors de l'utilisation du service.</p>
                            <p>
                                Les données d'utilisation peuvent inclure des informations telles que l'adresse de protocole Internet
                                de votre appareil (par exemple, l'adresse IP), le type de navigateur, la version du navigateur,
                                les pages de notre service que vous visitez, l'heure et la date de votre visite, le temps passé sur
                                ces pages.
                            </p>
                            <p>
                                Lorsque vous accédez au service par ou via un appareil mobile, nous pouvons collecter certaines informations
                                automatiquement, y compris, mais sans s'y limiter, le type d'appareil mobile que vous utilisez, l'adresse IP de votre appareil mobile, votre mobile système d'exploitation,
                                le type de navigateur Internet mobile que vous utilisez.
                            </p>
                            <p>
                                Nous pouvons également collecter des informations que votre navigateur envoie chaque fois que vous visitez
                                notre service ou lorsque vous accédez au service par ou via un appareil mobile.
                            </p>

                            <h3 className='mt-3'>Utilisation de vos données personnelles</h3>
                            <p className='fw-bold'>La Société peut utiliser les données personnelles aux fins suivantes :</p>
                            <p>
                                <span className='fw-bold' style={{
                                    color: "#ff5622"
                                }}>Pour fournir et maintenir notre service </span>, y compris pour surveiller l'utilisation de notre service.
                            </p>
                            <p>
                                <span className='fw-bold' style={{
                                    color: "#ff5622"
                                }}>Pour gérer votre compte :</span> pour gérer votre inscription en tant qu'utilisateur du service. Les données personnelles que vous fournissez
                                peuvent vous donner accès à différentes fonctionnalités du service qui vous sont disponibles en tant qu'utilisateur enregistré.
                            </p>
                            <p>
                                <span className='fw-bold' style={{
                                    color: "#ff5622"
                                }}>Pour l'exécution d'un contrat :</span> le développement, la conformité et l'engagement du contrat d'achat pour les produits, articles
                                ou services que vous avez achetés ou de tout autre contrat avec nous via le service.
                            </p>
                            <p>
                                <span className='fw-bold' style={{
                                    color: "#ff5622"
                                }}>Pour vous contacter :</span> Pour vous contacter par e-mail, appels téléphoniques, SMS ou autres formes équivalentes de communication
                                électronique, telles que les notifications push d'une application mobile concernant les mises à jour ou les communications informatives
                                liées aux fonctionnalités, produits ou services sous contrat, y compris les mises à jour de sécurité, lorsque cela est nécessaire ou
                                raisonnable pour leur mise en œuvre.
                            </p>
                            <p>
                                <span className='fw-bold' style={{
                                    color: "#ff5622"
                                }}>Pour gérer vos demandes :</span> pour assister et gérer vos demandes à nous.
                            </p>
                            <p>
                                <span className='fw-bold' style={{
                                    color: "#ff5622"
                                }}>À d'autres fins : </span>Nous pouvons utiliser vos informations à d'autres fins, telles que l'analyse des données, l'identification des
                                tendances d'utilisation, la détermination de l'efficacité de nos campagnes promotionnelles et pour évaluer et améliorer notre service,
                                nos produits, nos services, notre marketing et votre expérience.
                            </p>
                            <p>
                                <span className='fw-bold' style={{
                                    color: "#ff5622"
                                }}>Partage de vos données personnelles : </span>Nous ne transmettons pas vos données.
                            </p>
                            <h3>Conservation de vos données personnelles</h3>
                            <p>
                                La Société conservera vos données personnelles uniquement le temps nécessaire aux fins énoncées dans la présente politique de confidentialité.
                                Nous conserverons et utiliserons vos données personnelles dans la mesure nécessaire pour nous conformer à nos obligations légales (par exemple,
                                si nous sommes tenus de conserver vos données pour nous conformer aux lois applicables), résoudre les litiges et appliquer nos accords et
                                politiques juridiques.
                            </p>
                            <p>
                                La Société conservera également les données d'utilisation à des fins d'analyse interne. Les données d'utilisation sont généralement conservées pendant
                                une période plus courte, sauf lorsque ces données sont utilisées pour renforcer la sécurité ou pour améliorer la fonctionnalité de notre service,
                                ou nous sommes légalement obligés de conserver ces données pendant des périodes plus longues.
                            </p>
                            <h3>Transfert de vos données personnelles</h3>
                            <p>
                                Vos informations, y compris les données personnelles, sont traitées dans les bureaux d'exploitation de la société et dans tout autre endroit où se trouvent
                                les parties impliquées dans le traitement. Cela signifie que ces informations peuvent être transférées et conservées sur des ordinateurs situés en dehors
                                de votre état, province, pays ou autre juridiction gouvernementale où les lois sur la protection des données peuvent différer de celles de votre juridiction.
                            </p>
                            <p>Votre consentement à cette politique de confidentialité suivi de la soumission de ces informations représente votre accord à ce transfert.</p>
                            <p>
                                La Société prendra toutes les mesures raisonnablement nécessaires pour garantir que vos données sont traitées en toute sécurité et conformément à la présente
                                politique de confidentialité et aucun transfert de vos données personnelles n'aura lieu à une organisation ou à un pays à moins que des contrôles adéquats
                                soient en place, y compris la sécurité de Vos données et autres informations personnelles.
                            </p>
                            <h3>Divulgation de vos données personnelles</h3>
                            <h4>Business Transactions</h4>
                            <p>
                                Si la Société est impliquée dans une fusion, une acquisition ou une vente d'actifs, Vos Données Personnelles peuvent être transférées. Nous vous informerons
                                avant que vos données personnelles ne soient transférées et deviennent soumises à une politique de confidentialité différente.
                            </p>
                            <h4>Forces de l'ordre</h4>
                            <p>
                                Dans certaines circonstances, la Société peut être tenue de divulguer vos données personnelles si la loi l'exige ou en réponse à des demandes valides des
                                autorités publiques (par exemple, un tribunal ou une agence gouvernementale).
                            </p>
                            <h4>Autres exigences légales</h4>
                            <p>La Société peut divulguer vos données personnelles en croyant de bonne foi qu'une telle action est nécessaire pour :</p>
                            <ui>
                                <li>Se conformer à une obligation légale</li>
                                <li>Protéger et défendre les droits ou la propriété de l'entreprise</li>
                                <li>Prévenir ou enquêter sur d'éventuels actes répréhensibles en relation avec le Service</li>
                                <li>Protéger la sécurité personnelle des Utilisateurs du Service ou du public</li>
                                <li>Protéger contre la responsabilité légale</li>
                            </ui>
                            <h3 className='mt-5'>Sécurité de vos données personnelles</h3>
                            <p>
                                La sécurité de vos données personnelles est importante pour nous, mais rappelez-vous qu'aucune méthode de transmission sur
                                Internet ou méthode de stockage électronique n'est sécurisée à 100%. Bien que nous nous efforcions d'utiliser des moyens
                                commercialement acceptables pour protéger vos données personnelles, nous ne pouvons garantir leur sécurité absolue.
                            </p>
                            <h2>Confidentialité des enfants</h2>
                            <p>
                                Notre service ne s'adresse à personne de moins de 18 ans. Nous ne collectons pas sciemment d'informations personnellement
                                identifiables de toute personne de moins de 18 ans. Si vous êtes un parent ou un tuteur et que vous savez que votre enfant
                                nous a fourni des données personnelles, veuillez <Link to="/contact">nous contacter</Link>. Si nous apprenons que nous avons collecté des données
                                personnelles de toute personne de moins de 18 ans sans vérification du consentement parental, nous prenons des mesures
                                pour supprimer ces informations de nos serveurs.
                            </p>
                            <p>
                                Si nous devons nous fier au consentement comme base légale pour le traitement de vos informations et que votre pays exige le consentement
                                d'un parent, nous pouvons exiger le consentement de vos parents avant de collecter et d'utiliser ces informations.
                            </p>
                            <h2>Liens vers d'autres sites Web</h2>
                            <p>
                                Notre service peut contenir des liens vers d'autres sites Web qui ne sont pas exploités par nous. Si vous cliquez sur un lien tiers, vous serez
                                dirigé vers le site de ce tiers. Nous vous conseillons vivement de consulter la politique de confidentialité de chaque site que vous visitez.
                            </p>
                            <p>
                                Nous n'avons aucun contrôle et n'assumons aucune responsabilité pour le contenu, les politiques de confidentialité ou les pratiques de tout site ou service tiers.
                            </p>
                            <h2>Modifications de cette politique de confidentialité</h2>
                            <p>
                                Nous pouvons mettre à jour notre politique de confidentialité de temps à autre. Nous vous informerons de tout changement en publiant la nouvelle
                                politique de confidentialité sur cette page.
                            </p>
                            <p>
                                Nous vous informerons par e-mail et / ou par un avis bien visible sur notre service, avant que le changement ne devienne effectif, et nous mettrons
                                à jour la date de «dernière mise à jour» en haut de cette politique de confidentialité.
                            </p>
                            <p>
                                Il vous est conseillé de consulter périodiquement cette politique de confidentialité pour tout changement. Les modifications apportées à
                                cette politique de confidentialité sont effectives lorsqu'elles sont publiées sur cette page.
                            </p>
                            <h2>Nous contacter</h2>
                            <p>Si vous avez des questions sur cette politique de confidentialité, vous pouvez nous contacter :</p>
                            <p>
                                <span className='fw-bold'>Par téléphone :</span> <a href="tel:0465230000" className='link-hover-primary'>04 65 23 00 00 </a><br />
                                <span className='fw-bold'>Par mail :</span> <a href="mailto:contact@yogan.fr" className='link-hover-primary'>contact@yogan.fr</a>
                            </p>
                        </div>
                    </div>
                </div >
            </div >
            <Footer />
        </>
    )
}