import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Elements
import BackToTop from './elements/back-top';
import ScrollToTop from './elements/ScrollToTop';

// Home Pages
import Index0 from './pages/home/yogan';

// Team Pages
import Team from './pages/team/team';

// Contact Pages
import Contact from './pages/contact/contact';

// Error Pages
import Error from './pages/error/error-404';
import ErrorModelIIS from './pages/error/error-404-model';

// ---- > Blog en travaux 
// import BlogActualites from './pages/blog/blog';
// import BlogDetailsSidebar from './pages/blog/blog-details/blog-details-1';

//Mentions legales
import MentionsLegales from './pages/juridical/mentions-legales';

//Politique de confidentialité
import PolitiqueDeConfidentialite from './pages/juridical/politique-de-confidentialite';

// Page Securite
import Securite from './pages/security/securite';

// Page Copyright 
import Copyright from './pages/juridical/copyright';

// Page Recrutement 
import Recrutement from './pages/recruitment/recrutement';

//Page development
import Logiciels from './pages/development/logiciels';
import Web from './pages/development/web';
import NosSitesWeb from './pages/sites/sites';
import ConnecteursAPI from './pages/development/connecteurs-api';
import AppMobile from './pages/development/app-mobile';
import Infinity from './pages/mobiles/infinity';
import YogNoteDeFrais from './pages/mobiles/yognotedefrais';
import PlCMobile from './pages/mobiles/politique-de-confidentialite';

//Page Support
import Support from './pages/support/support';

//Page Astreintes
// import Astreintes from './pages/support/astreintes';

// Page References
import References from './pages/references/references';

// Page Plan Site
import PlanSite from './pages/juridical/plan-de-site';
import Partners from './pages/references/partners';


export default function Markup(props) {
	return (
		<>
			<BrowserRouter basename={'/'}>
				<ScrollToTop />
				<Routes>
					{/* Home Pages */}
					<Route path='/' element={<Index0 />} />

					{/* Team Pages */}
					<Route path='/equipe-yogan-developpement' element={<Team />} />

					{/* Partners Pages */}
					<Route path='/partenaires' element={<Partners />} />

					{/* Contact Pages */}
					<Route path='/contact' element={<Contact />} />

					{/* Error Pages */}
					<Route path='*' element={<Error />} />
					<Route path='/404' element={<ErrorModelIIS />} />

					{/* Blog Pages */}
					{/* <Route path='/blog' element={<BlogActualites />} />
					<Route path={`/blog/:slug`} element={<BlogDetailsSidebar />} /> */}

					{/* Mentions Legales Pages */}
					<Route path="/mentions-legales" element={<MentionsLegales />} />

					{/* Mentions Legales Pages */}
					<Route path="/politique-de-confidentialite" exact element={<PolitiqueDeConfidentialite />} />

					{/* Securite Page */}
					<Route path='/securite' element={<Securite />} />

					{/* News Pages */}
					<Route path='/recrutement' element={<Recrutement />} />

					{/* Development Pages */}
					<Route path='/logiciels' element={<Logiciels />} />
					<Route path='/web' element={<Web />} />
					<Route path='/sites' element={<NosSitesWeb />} />
					<Route path='/nos-references' element={<References />} />
					<Route path='/connecteurs-api' element={<ConnecteursAPI />} />
					<Route path='/application-mobile-metier' element={<AppMobile />} />

					{/* Mobiles Pages */}
					<Route path='/infinity' element={<Infinity />} />
					<Route path='/yognotedefrais' element={<YogNoteDeFrais />} />
					<Route path='/politique-de-confidentialite-mobile' element={<PlCMobile />} />


					{/*Support Pages*/}
					<Route path='/support' element={<Support />} />
					{/* <Route path='/astreintes' element={<Astreintes/>} /> */}

					{/* Page Plan Site */}
					<Route path='/plan-de-site' element={<PlanSite />} />

					{/* Page Copyright */}
					<Route path='/copyright' element={<Copyright />} />

				</Routes>
			</BrowserRouter>
			<BackToTop />
		</>
	);
}