import React, { useState, useEffect } from 'react';

// Get Api
import axios from "axios";
import config from "../../../config.json";

// Markdown 
import ReactMarkdown from "react-markdown";

// Layout
import Header from "../../layout/header/header";
import Footer from "../../layout/footer/footer3";

//Element
import Banner from '../../elements/banner/banner-2';
import SEO from '../../elements/seo/seo';
import CounterText from '../../containers/counter-text';

// List 
import List1 from '../../elements/list/list-1';

// Title
import Title1 from '../../elements/title/title-1';

export default function Logiciels() {

	// SETTER 
	const [meta, setMeta] = useState('')
	const [banner, setBanner] = useState('')
	const [sec1, setSec1] = useState('')
	const [counter, setCounter] = useState('')

	// Manage static data 
	const [err, setErr] = useState(false)

	// List 1 
	const [titleList1, setTitleList1] = useState('')
	const [dataList1, setDataList1] = useState([])

	// List 2 
	const [List2, setList2] = useState('')

	useEffect(() => {
		axios.get(`${config.urlServer}api/logiciels`)
			.then(function (res) {
				setMeta(res.data)
				setBanner(res.data.Composents[0])
				setSec1(res.data.Composents[1])
				setCounter(res.data.Composents[1].Data[0])

				// List 1 
				setTitleList1(res.data.Composents[2].Titre)
				setDataList1(res.data.Composents[2].Data)

				// List 2 
				setList2(res.data.Composents[3])
			})
			.catch(err => {
				setErr(true)
			})
	},[]);

	// CONST 
	const metaData = {
		title: !meta.TitreMeta ? "Développeur d'applicatif pour les professionnels" : meta.TitreMeta,
		description: !meta.description ? "Pendant plus de 18 ans, YOGAN Développement s'est spécialisé dans la réalisation d'outils et solutions sur mesure pour des clients aux activités variées et exigeantes." : meta.description,
		canonical: !meta.URLCanonical ? "logiciels" : meta.URLCanonical
	}

	const bannerContent = {
		title: !banner.Titre ? "Logiciels" : banner.Titre,
		entitled: !banner.Corps ? "Logiciels" : banner.Corps
	}

	const titleList = {
		titleList1: !titleList1 ? "quelques-un des projets qui nous ont été confiés :" : titleList1,
		titleList2: !List2.Titre ? "Outils fréquemment demandés : " : List2.Titre
	}

	// STATIC DATA
	const listStatic1 = [
		{
			title: "gestion complète de chaînes de magasins",
			corps: "Outil de caisses, Gestion des stocks, Planification des achats, Facturation, Resources humaines ..."
		},
		{
			title: "gestion complète de bureau d'étude et gestion de patrimoine",
			corps: "Gestion relations clients, Catalogue complexe de fonds et placements, Standard téléphonique, Administration web, Conformité à légilstation ..."
		},
		{
			title: "call-center de service",
			corps: "Mise en relation clients & intervenants sur disponibilité / spécialité / distance ..."
		},
		{
			title: "gestion d'association",
			corps: "Suivi des membres, Petites Annonces, Inscription au évènements, Cotisations, Boutique en ligne, Mailing ..."
		},
		{
			title: "outils de gestion éprouvés ",
			corps: "Notre expérience auprès de nos clients nous a permis d'acquérir une grande expérience dans la création d'outils de gestion pour tous les domaines d'une activité d'entreprise."
		},
		{
			title: "outils pour les artisans de service",
			corps: "Réalisation d'un outil pour les artisans de service (plombier, serrurier, chauffagiste, électricien ...) et leurs techniciens avec un BackOffice pour la préparation de fiche d'intervention et la programmation de l'intervation. Et une applicaiton mobile pour les techniciens pour la reception et gestion des interventions."
		},
	]

	return (
		<>
			{/* Menu */}
			<Header />

			{/* Dynamic Meta Tags */}
			<SEO
				title={metaData.title}
				description={metaData.description}
				canonical={metaData.canonical}
			/>

			<Banner title={bannerContent.title} entitled={bannerContent.entitled} navTitle="Accueil" navLink="/" />

			<div className="page-content bg-white">
				<div className="content-block" id="content-area">
					<div className="section-area section-sp1 bg-gray">
						<div className="container">


							{/* <!-- Notre expérience Start --> */}
							<CounterText data={sec1} counter={counter} />
							{/* <!-- Notre expérience End --> */}


							<div className="row">
								<div className="col-lg-12 col-md-6">

									{/* <!-- Quelques-uns des projets qui nous ont été confiés Start --> */}
									<List1 title={titleList.titleList1} list={dataList1} static={listStatic1} err={err} />
									{/* <!-- Quelques-uns des projets qui nous ont été confiés End --> */}


									{/* <!-- Outils fréquemment demandés Start --> */}
									<Title1 title={titleList.titleList2} fontSize={"28px"} textTransform={"capitalize"} />
									<ReactMarkdown>{List2.Corps}</ReactMarkdown>
									{/* <!-- Outils fréquemment demandés End --> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}