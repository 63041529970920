import React from "react";

export default function Title1(props) {
    const title = props.title

    const style = {
        h: {
            fontSize: props.fontSize,
            textAlign: props.textAlign,
            fontWeight: props.fontWeight,
            textDecoration: props.textDecoration,
            textTransform: props.textTransform
        }
    }
    return (
        <>
            <h2 className="title-head" style={style.h}>{title}</h2>
        </>
    )
}