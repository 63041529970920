import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import ImageViewer from 'react-simple-image-viewer';

// Layout
import Header from "../../layout/header/header";
import Footer from "../../layout/footer/footer3";

//Elements
import Banner from '../../elements/banner/banner-2';
import SEO from '../../elements/seo/seo';

// Images
import pic1 from '../../../images/infinity/infinity-1.webp';
import pic2 from '../../../images/infinity/infinity-2.webp';

// Icons
import { FiArrowRight } from 'react-icons/fi';

export default function Infinity() {

    // Carousel
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const images = [
        pic1,
        pic2
    ];

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };


    return (
        <>
            <Header />

            {/* Dynamic Meta Tags */}
            <SEO
                title="Infinity - Application Mobile métier WinDev"
                description="Application mobile métier WinDev Mobile sur mesure pour les professionnels et les secteurs métiers ciblés."
                canonical="infinity"
            />
            <Banner title="Infinity" entitled="Application mobile" navTitle="Accueil" navLink="/" />

            <div className="content-block" id="content-area">
                <div className="section-area section-sp1 bg-gray">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-6 mb-4">
                                <h2 className="text-capitalize">
                                    <a href="https://play.google.com/store/apps/details?id=fr.yogan.infinity" target="_blank" rel="noreferrer"> 📱 Infinity</a>
                                </h2>

                                <p className="fw-bold">
                                    Application de suivi d’intervention pour les artisans. Adapter à divers métiers comprenant des interventions.
                                </p>
                                <p>
                                    S’adressent à tous les artisans qui devront gérer plusieurs
                                    salariés exécutant des interventions en clientèle et devant suivre
                                    ces interventions pour faire des comptes rendus pour les assureurs
                                    ou à tout autre maitre d’œuvre.
                                </p>
                                <p>
                                    Cela en fait l’outil idéal pour un artisan avec des interventions comprenant plusieurs intervenants.
                                </p>
                                <p className="fw-bold">Liste non-exaustive des fonctionnalités : </p>
                                <div className="mt-4 mb-4">
                                    <div className="row">
                                        <div className="col-6">
                                            <ul className="list-group" style={{ paddingLeft: "27px" }}>
                                                <div>

                                                    <li className="puce-change">Affichage des interventions en cours ou à traiter dans la journée</li>
                                                    <li className="puce-change">Suivi des interventions sur tablette ou mobile en temps réel</li>
                                                    <li className="puce-change">Modification, suppression et ajout d’une intervention</li>
                                                    <li className="puce-change">Photos du produit client avant et après pose</li>
                                                    <li className="puce-change">Signalement de la fin d’une intervention</li>
                                                </div>
                                            </ul>
                                        </div>
                                        <div className="col-6">
                                            <ul className="list-group" style={{ paddingLeft: "40px" }}>
                                                <li className="puce-change">Présentation des articles et des stocks</li>
                                                <li className="puce-change">Gestion des collaborateurs</li>
                                                <li className="puce-change">Gestions des facturations</li>
                                                <li className="puce-change">Plannings des techniciens</li>
                                                <li className="puce-change">Suivi des dossiers clients</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='image-content mt-4'>
                                    {images.map((src, index) => (
                                        <img
                                            src={src}
                                            onClick={() => openImageViewer(index)}
                                            width="500"
                                            key={index}
                                            style={{ margin: '2px', height: "100%", paddingTop: '8px' }}
                                            alt="Yogan développement application mobile infinity"
                                        />
                                    ))}

                                    {isViewerOpen && (
                                        <ImageViewer
                                            src={images}
                                            currentIndex={currentImage}
                                            disableScroll={false}
                                            closeOnClickOutside={true}
                                            onClose={closeImageViewer}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className='mt-4'>
                                <h3 className='h4'>Faciles à mettre en œuvre et opérationnels de suite</h3>
                                <p>
                                    Chacune de nos applications mobiles métiers est développée sur mesure selon les besoins et les
                                    objectifs de l’entreprise utilisatrice (gestions administratives, gestion du personnel, acquisition de client, etc...).
                                </p>
                                <p>
                                    Notre objectif premier est d’améliorer la productivité de vos collaborateurs et de votre entreprise
                                    avec un outil spécialement conçu pour répondre aux besoins spécifiques de votre secteur métier.
                                </p>
                                <p>
                                    Demandez <span className="fw-bold">gratuitement</span> un devis en nous adressant une demande via nos <Link to='/contact'>formulaires de contact</Link> en nous
                                    laissant vos coordonnées d’entreprise ou contactez-nous directement par téléphone au <a href="tel:0465230000" className="link-hover-primary">04 65 23 00 00</a>.
                                    Notre équipe fera suite à votre demande dans les plus brefs délais.
                                </p>
                            </div>
                            <span><FiArrowRight /><Link to="/politique-de-confidentialite-mobile" className='link-hover-primary'> Notre politique de confidentialité</Link></span>
                            <div className="text-center mt-5 mb-5">
                                <Link to="/contact" className="btn button-md rounded-pill">Demander une démo</Link>
                                {/* <a href="https://play.google.com/store/apps/details?id=fr.yogan.infinity" className="btn button-md" target="_blank" rel="noreferrer">Tester l'application</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
