import React from 'react';
import { Helmet } from 'react-helmet';

export default function Hubspot (){
	return(
		<>
			<Helmet>
				<script type="text/javascript" id="hs-script-loader" async="" defer="" src="//js.hs-scripts.com/8401183.js"></script>		
			</Helmet>
		</>
	);
    
}
