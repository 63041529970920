import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Get Api
import axios from "axios";
import config from "../../../config.json";

// Markdown 
import ReactMarkdown from "react-markdown";

// Layout
import Header from "../../layout/header/header";
import Footer from "../../layout/footer/footer3";

// Element
import SEO from '../../elements/seo/seo';
import Banner from '../../elements/banner/banner-1';

// Title
import Title1 from "../../elements/title/title-1";

export default function MentionsLegales() {

	// SETTER 
	const [data, setData] = useState('')

	// Manage static data 
	const [err, setErr] = useState(false)

	useEffect(() => {
		axios.get(`${config.urlServer}api/mentions-legales`)
			.then(function (res) {
				setData(res.data)
			})
			.catch(err => {
				setErr(true)
			})
	},[]);

	// CONST 
	const metaData = {
		title: !data.TitreMeta ? "Mentions légales de Yogan Développement" : data.TitreMeta,
		description: !data.DescriptionMeta ? "Informations légales du site et mentions légales de Yogan Développement." : data.DescriptionMeta,
		canonical: !data.Canonical ? "mentions-legales" : data.Canonical
	}

	const bannerContent = {
		title: !data.TitreBanner ? "Mentions légales" : data.TitreBanner,
	}

	return (
		<>
			{/* Menu */}
			<Header />

			{/* Dynamic Meta Tags */}
			<SEO
				title={metaData.title}
				description={metaData.description}
				canonical={metaData.canonical}
			/>


			<Banner title={bannerContent.title} entitled={bannerContent.title} navTitle="Accueil" navLink="/" />

			<div className="section-area section-sp1" id="content-area">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-6">


							{/* <!-- Éditeur Start --> */}
							<div className='mb-4'>
								<Title1 title={data.EditeurTitre} fontSize={"20px"} />
								<ReactMarkdown>{data.EditeurCorps}</ReactMarkdown>
							</div>
							{/* <!-- Éditeur End --> */}


							{/* <!-- Hébergement Start --> */}
							<div className='mb-4'>
								<Title1 title={data.HebergementTitre} fontSize={"20px"} />
								<ReactMarkdown>{data.HebergementCorps}</ReactMarkdown>
								<ReactMarkdown>{data.Telephone}</ReactMarkdown>
								<ReactMarkdown>{data.Email}</ReactMarkdown>
							</div>
							{/* <!-- Hébergement End --> */}


							{/* <!-- Réutilisation des données Start --> */}
							<div className='mb-4'>
								<Title1 title={data.ContenuTitre} fontSize={"20px"} />
								<ReactMarkdown>{data.ContenuCorps}</ReactMarkdown>
							</div>
							{/* <!-- Réutilisation des données End --> */}


							{/* <!-- Données à caractère personnel Start --> */}
							<div>
								<Title1 title={data.DonneeTitre} fontSize={"20px"} />
								<ReactMarkdown>{data.DonneeCorps}</ReactMarkdown>
							</div>
							{/* <!-- Données à caractère personnel End --> */}


							{/* <!-- Non-responsabilité Start --> */}
							<div className='mb-5'>
								<Title1 title={data.NonRespoTitre} fontSize={"20px"} />
								<ReactMarkdown>{data.NonRespoCorps}</ReactMarkdown>
							</div>
							{/* <!-- Non-responsabilité End --> */}


							{/* Static data if we not receive data from express */}
							{
								err &&
								<div>
									<div className='mb-4'>
										<h5>Éditeur</h5>
										<p>
											Le site <Link to="/" className='link-hover-primary'>www.yogan.fr</Link> est édité par Yogan Développement, domiciliée au 4 Av. de l'Opéra 75001, Paris (France), SAS au capital social de 13 155 € - SIREN : 490 084 274.
											Yogan est totalement indépendante et reste totalement maître du contenu éditoriale du site.
										</p>
									</div>
									<div className='mb-4'>
										<h5>Hébergement</h5>
										<p>
											Le site <Link to="/" className='link-hover-primary'>www.yogan.fr</Link> est hébergé par la société Yogan Développement et peut être joint aux contact suivants :
										</p>
										<p>
											Téléphone : <a href="tel:0465230000" className='link-hover-primary'>04 65 23 00 00 </a><br />
											Mail de l’éditeur: <a href="mailto:contact@yogan.fr" className='link-hover-primary'>contact@yogan.fr</a>
										</p>
									</div>
									<div className='mb-4'>
										<h5>Réutilisation des contenus</h5>
										<p>
											L’ensemble du matériel du site <Link to="/" className='link-hover-primary'>www.yogan.fr</Link>, incluant les textes, les éléments graphiques, les éléments sonores et audiovisuels, les outils de suivi, est la propriété de Yogan, éditeur du site, et est protégé par les lois internationales sur les droits d’auteur.
											Aucun matériel de ce site ne peut être reproduit, modifié, distribué, transmis, republié, affiché ou diffusé sans l’autorisation écrite préalable de Yogan. Pour toute demande relative à l’utilisation du matériel, veuillez écrire à : <a href="mailto:contact@yogan.fr" className='link-hover-primary'>contact@yogan.fr</a>.
										</p>
									</div>
									<div>
										<h5>Données à caractère personnel</h5>
										<p>
											Vos données à caractère personnel sont exclusivement destinées au responsable de traitement et aux autres Utilisateurs du Site et ne seront en aucun cas communiquées à des tiers non autorisés.
											Vous bénéficiez d’un droit d’accès, de rectification et d’opposition des données à caractère personnel vous concernant que vous pouvez exercer en vous adressant par voie postale à Yogan, 4 Av. de l'Opéra 75001, Paris (France).
										</p>
									</div>
									<div className='mb-5'>
										<h5>Non-responsabilité</h5>
										<p>
											Le site <Link to="/" className='link-hover-primary'>www.yogan.fr</Link> contient des liens permettant un accès direct à d’autres sites internet externes.
											Yogan ne reconnaît aucune responsabilité sur l’information diffusée par ces sites externes, et n’exerce sur celle-ci aucun contrôle éditorial.
										</p>
									</div>
								</div>
							}

						</div>
					</div>
				</div>
			</div>


			<Footer />
		</>
	);

}