import React from "react";
import { Link as ScrollTo } from 'react-scroll'
import { Link } from 'react-router-dom';
// import Error from "images/error/9195-error.gif"
// Picture
import bannerPic1 from '../../../images/banner/banner1.webp';

export default function Banner1(props) {

    return (
        <>
            <div className="page-banner ovpr-dark overlay-dotted ovdt1 parallax" style={{ backgroundImage: "url(" + bannerPic1 + ")" }}>
                <div className="container">
                    <div className="page-banner-entry">
                    <div className="containerNeon"><p className="neonText">404</p></div>
                        <h1 className="text-white">{props.title}</h1>
                        <p className="text-white down">{props.texte}</p>
                        <div className="breadcrumb-row">
                            <ul className="list-inline">
                                <li><Link to={props.navLink}><i className="fa fa-home"></i>{props.navTitle}</Link></li>
                            </ul>
                        </div>
                        <p className="text-white up">{props.texte2}</p>
                    </div>
                </div>
                <ScrollTo smooth={true} to="content-area" className="banner-bottom scroll-page"><i className="ti-arrow-down"></i></ScrollTo>
            </div>
        </>
    )
}