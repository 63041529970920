import React, { useState, useEffect } from "react";

// Get Api
import axios from "axios";
import config from "../../../config.json";

// Markdown 
import ReactMarkdown from "react-markdown";

// Layout
import Header from "../../layout/header/header";
import Footer from "../../layout/footer/footer3";

// Element
import Banner from '../../elements/banner/banner-2';
import Card from "../../elements/card/card-4";
import SEO from '../../elements/seo/seo';

// Title
import Title1 from "../../elements/title/title-1";

// Button 
import Button2 from "../../elements/button/button-2";

export default function Securite() {

    // SETTER 
    const [meta, setMeta] = useState('')
    const [banner, setBanner] = useState('')
    const [data, setData] = useState('')

    // CARDS 
    const [card1, setCard1] = useState('')
    const [card2, setCard2] = useState('')
    const [card3, setCard3] = useState('')
    const [card4, setCard4] = useState('')
    const [card5, setCard5] = useState('')
    const [card6, setCard6] = useState('')
    const [card7, setCard7] = useState('')
    const [card8, setCard8] = useState('')

    useEffect(() => {
        axios.get(`${config.urlServer}api/securite`)
            .then(function (res) {
                setMeta(res.data)
                setBanner(res.data.Composents[0])
                setData(res.data.Composents[1])
                setCard1(res.data.Composents[2].Data[0].Titre)
                setCard2(res.data.Composents[2].Data[1].Titre)
                setCard3(res.data.Composents[2].Data[2].Titre)
                setCard4(res.data.Composents[2].Data[3].Titre)
                setCard5(res.data.Composents[2].Data[4].Titre)
                setCard6(res.data.Composents[2].Data[5].Titre)
                setCard7(res.data.Composents[2].Data[6].Titre)
                setCard8(res.data.Composents[2].Data[7].Titre)
            })
    },[]);

    const metaData = {
        title: !meta.TitreMeta ? "La sécurité de vos données est importante pour nous !" : meta.TitreMeta,
        description: !meta.description ? "La protection de vos données est importante pour nous. Dans le respect du RGPD ( Règlement général sur la protection des données ) nous faisons de la sécurité de vos données une priorité. Depuis la conception jusqu’à l’hébergement et la maintenance de votre application." : meta.description,
        canonical: !meta.URLCanonical ? "securite" : meta.URLCanonical
    }

    const contentBanner = {
        titlte: !banner.Titre ? "Sécurité" : banner.Titre,
        entitled: !banner.Corps ? "Sécurité" : banner.Corps
    }

    const button = {
        BTNLib: !data.BTNLib ? "en savoir plus" : data.BTNLib,
        BTNUrl: !data.BTNUrl ? "/contact" : data.BTNUrl
    }

    return (
        <>
            {/* Menu */}
            <Header />

            {/* Dynamic Meta Tags */}
            <SEO
                title={metaData.title}
                description={metaData.description}
                canonical={metaData.canonical}
            />


            <Banner title={contentBanner.titlte} entitled={contentBanner.entitled} navTitle="Accueil" navLink="/" />

            {/* <!-- La sécurité de vos données Start --> */}
            <div className="page-content bg-gray">
                <div className="section-area section-sp2" id="content-area">
                    <div className="container">
                        <Title1 title={data.Titre} fontSize={"28px"} textAlign={"center"} fontWeight={"unset"} />
                        <ReactMarkdown className='text-center'>{data.Corps}</ReactMarkdown><br />
                        <Card card1={card1} card2={card2} card3={card3} card4={card4} card5={card5} card6={card6} card7={card7} card8={card8} /><br />
                        <Button2 data={button} />
                    </div>
                </div>
            </div>
            {/* <!-- La sécurité de vos données End --> */}

            <Footer />
        </>
    )
}