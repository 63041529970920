import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import config from "../../../config.json";

// Layout
import Header from '../../layout/header/header';
import Footer from '../../layout/footer/footer3';

// Element
import Banner from '../../elements/banner/banner-1';
import SEO from '../../elements/seo/seo';

// Picture
import Team1 from "../../../images/team/pic1.webp";
import TeamGif1 from "../../../images/team/pic11.webp";
import Team2 from "../../../images/team/pic2.webp";
import TeamGif2 from "../../../images/team/pic22.webp";
import Team3 from "../../../images/team/pic3.webp";
import TeamGif3 from "../../../images/team/pic33.webp";
import Team4 from "../../../images/team/pic4.webp";
import TeamGif4 from "../../../images/team/pic44.webp";
import Team5 from "../../../images/team/pic5.webp";
import TeamGif5 from "../../../images/team/pic55.webp";
import Team6 from "../../../images/team/pic6.webp";
import TeamGif6 from "../../../images/team/pic66.webp";

// Avatar Picture 
import avatarSacha from "../../../images/team/sacha.webp";
import avatarAlain from "../../../images/team/alain.webp";
import avatarJoseph from "../../../images/team/joseph.webp";
import avatarJulien from "../../../images/team/julien.webp";
import avatarAuriane from "../../../images/team/auriane.webp";
import avatarLilian from "../../../images/team/lilian.webp";
import avatarMelissa from "../../../images/team/melissa.webp";
import avatarPhilippe from "../../../images/team/philippe.webp";
import avatarChristophe from "../../../images/team/christophe.webp";

// Text
import Text1 from '../../elements/text/text-1';

// Buttun 
import Button1 from '../../elements/button/button-1';

export default function Team() {

	// SETTER 
	const [meta, setMeta] = useState('')
	const [banner, setBanner] = useState('')
	const [sec1, setSec1] = useState('')
	const [sec3, setSec3] = useState('')

	// TEAMS
	const [sec2pers0, setSec2Pers0] = useState('')
	const [sec2pers1, setSec2Pers1] = useState('')
	const [sec2pers2, setSec2Pers2] = useState('')
	const [sec2pers3, setSec2Pers3] = useState('')
	const [sec2pers4, setSec2Pers4] = useState('')
	const [sec2pers5, setSec2Pers5] = useState('')
	const [sec2pers6, setSec2Pers6] = useState('')
	const [sec2pers7, setSec2Pers7] = useState('')
	const [sec2pers8, setSec2Pers8] = useState('')
	const [sec2pers9, setSec2Pers9] = useState('')
	const [sec2pers10, setSec2Pers10] = useState('')
	const [sec2pers11, setSec2Pers11] = useState('')
	const [sec2pers12, setSec2Pers12] = useState('')
	const [sec2pers13, setSec2Pers13] = useState('')
	const [sec2pers14, setSec2Pers14] = useState('')

	useEffect(() => {
		axios.get(`${config.urlServer}api/equipe`)
			.then(function (res) {
				setMeta(res.data)
				setBanner(res.data.Composents[0])
				setSec1(res.data.Composents[1])

				setSec2Pers0(res.data.Composents[2].Data[0])
				setSec2Pers1(res.data.Composents[2].Data[1])
				setSec2Pers2(res.data.Composents[2].Data[2])
				setSec2Pers3(res.data.Composents[2].Data[3])
				setSec2Pers4(res.data.Composents[2].Data[4])
				setSec2Pers5(res.data.Composents[2].Data[5])
				setSec2Pers6(res.data.Composents[2].Data[6])
				setSec2Pers7(res.data.Composents[2].Data[7])
				setSec2Pers8(res.data.Composents[2].Data[8])
				setSec2Pers9(res.data.Composents[2].Data[9])
				setSec2Pers10(res.data.Composents[2].Data[10])
				setSec2Pers11(res.data.Composents[2].Data[11])
				setSec2Pers12(res.data.Composents[2].Data[12])
				setSec2Pers13(res.data.Composents[2].Data[13])
				setSec2Pers14(res.data.Composents[2].Data[14])

				setSec3(res.data.Composents[4])
			})
	}, []);

	// CONST 
	const metaData = {
		title: !meta.TitreMeta ? "Développeur français indépendant d'applications et solution de gestion" : meta.TitreMeta,
		description: !meta.description ? "Développeur français indépendant d'applications et solution de gestion d'activités commerciales et associatives pour les professionnels." : meta.description,
		canonical: !meta.URLCanonical ? "equipe-yogan-developpement" : meta.URLCanonical
	}

	const bannerContent = {
		title: !banner.Titre ? "Notre Équipe" : banner.Titre,
		entitled: !banner.Corps ? "Équipe" : banner.Corps
	}

	const section2 = [
		{
			thumb: Team1,
			thumbGif: TeamGif1,
			Name: !sec2pers0.Titre ? "Simon Persat" : sec2pers0.Titre,
			Work: !sec2pers0.Corps ? "CEO & Fondateur" : sec2pers0.Corps
		},
		{
			thumb: Team2,
			thumbGif: TeamGif2,
			Name: !sec2pers1.Titre ? "Yannick Liabaud" : sec2pers1.Titre,
			Work: !sec2pers1.Corps ? "Chief Operating Officer" : sec2pers1.Corps
		},
		{
			thumb: Team5,
			thumbGif: TeamGif5,
			Name: !sec2pers2.Titre ? "Nicolas Lascombe" : sec2pers2.Titre,
			Work: !sec2pers2.Corps ? "Lead Tech" : sec2pers2.Corps
		},
		{
			thumb: Team6,
			thumbGif: TeamGif6,
			Name: !sec2pers3.Titre ? "Jean François Toracca" : sec2pers3.Titre,
			Work: !sec2pers3.Corps ? "Développeur back-end" : sec2pers3.Corps
		},
		{
			thumb: Team3,
			thumbGif: TeamGif3,
			Name: !sec2pers4.Titre ? "Nicola Gibault" : sec2pers4.Titre,
			Work: !sec2pers4.Corps ? "Développeur Web" : sec2pers4.Corps
		},
		{
			thumb: Team4,
			thumbGif: TeamGif4,
			Name: !sec2pers5.Titre ? "Katarzyna Rogowska" : sec2pers5.Titre,
			Work: !sec2pers5.Corps ? "Développeuse Web" : sec2pers5.Corps
		},
		{
			thumb: avatarSacha,
			thumbGif: "",
			Name: !sec2pers6.Titre ? "Sacha" : sec2pers6.Titre,
			Work: !sec2pers6.Corps ? "Développeur Web" : sec2pers6.Corps,
			sizeAvatar: "avatar-design"
		},
		{
			thumb: avatarAlain,
			thumbGif: "",
			Name: !sec2pers7.Titre ? "Alain" : sec2pers7.Titre,
			Work: !sec2pers7.Corps ? "Développeur back-end" : sec2pers7.Corps,
			sizeAvatar: "avatar-design"
		},
		{
			thumb: avatarJoseph,
			thumbGif: "",
			Name: !sec2pers8.Titre ? "Joseph" : sec2pers8.Titre,
			Work: !sec2pers8.Corps ? "Développeur back-end" : sec2pers8.Corps,
			sizeAvatar: "avatar-design"
		},
		{
			thumb: avatarJulien,
			thumbGif: "",
			Name: !sec2pers9.Titre ? "Julien" : sec2pers9.Titre,
			Work: !sec2pers9.Corps ? "Développeur back-end" : sec2pers9.Corps,
			sizeAvatar: "avatar-design"
		},
		{
			thumb: avatarAuriane,
			thumbGif: "",
			Name: !sec2pers10.Titre ? "Auriane" : sec2pers10.Titre,
			Work: !sec2pers10.Corps ? "RSSI" : sec2pers10.Corps,
			sizeAvatar: "avatar-design"
		},
		{
			thumb: avatarLilian,
			thumbGif: "",
			Name: !sec2pers11.Titre ? "Lilian" : sec2pers11.Titre,
			Work: !sec2pers11.Corps ? "Développeur mobile" : sec2pers11.Corps,
			sizeAvatar: "avatar-design"
		},
		{
			thumb: avatarMelissa,
			thumbGif: "",
			Name: !sec2pers12.Titre ? "Mélissa" : sec2pers12.Titre,
			Work: !sec2pers12.Corps ? "Développeuse web" : sec2pers12.Corps,
			sizeAvatar: "avatar-design"
		},
		{
			thumb: avatarPhilippe,
			thumbGif: "",
			Name: !sec2pers13.Titre ? "Philippe" : sec2pers13.Titre,
			Work: !sec2pers13.Corps ? "développeur back-end" : sec2pers13.Corps,
			sizeAvatar: "avatar-design"
		},
		{
			thumb: avatarChristophe,
			thumbGif: "",
			Name: !sec2pers14.Titre ? "Christophe" : sec2pers14.Titre,
			Work: !sec2pers14.Corps ? "développeur back-end" : sec2pers14.Corps,
			sizeAvatar: "avatar-design"
		}
	]

	const text1 = {
		title: !sec1.Titre ? "Rencontrez L'équipe Yogan" : sec1.Titre,
		p: !sec1.Corps ? "Développeur français indépendant d'applications et solution de gestion d'activités commerciales et associatives. Nous développons des outils sur-mesure en collaboration directe avec nos clients pour assurer un résultat adapté aux spécificités de chaque métier." : sec1.Corps,
		alignText: "center",
		psize: "18px"
	}

	const text2 = {
		title: !sec3.Titre ? "Nous Recrutons !" : sec3.Titre,
		p: !sec3.Corps ? "Vous êtes un professionnel aguerri et vous souhaitez-nous rejoindre ?" : sec3.Corps,
		alignText: "center",
		hsize: "28px"
	}

	const button = {
		BTNLib: !sec3.BTNLib ? "Nos offres d'emploi" : sec3.BTNLib,
		BTNUrl: !sec3.BTNUrl ? "/recrutement" : sec3.BTNUrl,
		style: "center"
	}

	return (
		<>
			{/* Menu */}
			<Header />

			{/* Dynamic Meta Tags */}
			<SEO
				title={metaData.title}
				description={metaData.description}
				canonical={metaData.canonical}
			/>


			<Banner title={bannerContent.title} entitled={bannerContent.entitled} navTitle="Accueil" navLink="/" />

			<div className="section-area section-sp1 team-page" id="content-area">
				<div className="container">

					{/* <!-- Rencontrez l'équipe yogan Start --> */}
					<Text1 text={text1} /><br />
					{/* <!-- Rencontrez l'équipe yogan End --> */}


					{/* <!-- Image équipe Start --> */}
					<div className="row mt-5">
						{section2.map((item, index) => (
							<div className="col-lg-4 col-md-6 col-sm-6" key={index}>
								<div className="team-mamber m-b20">
									<div className="team-media text-center">
										<img src={item.thumb} className={item.sizeAvatar} alt="" />
										<img src={item.thumbGif} className="team-gif" />
										{/* <ul className="team-social">
													<li><Link to={"https://www.facebook.com/yogansoftware/"}><i className="fa fa-facebook"></i></Link></li>
													<li><Link to={"https://mobile.twitter.com/yogansoftware"}><i className="fa fa-twitter"></i></Link></li>
													<li><Link to={"https://es.linkedin.com/company/yogan"}><i className="fa fa-linkedin"></i></Link></li>
												</ul> */}
									</div>
									<div className="team-info">
										<h4 className="title"><Link to={"#"}>{item.Name}</Link></h4>
										<span>{item.Work}</span>
									</div>
								</div>
							</div>
						))}
					</div>
					{/* <!-- Image équipe End --> */}


					{/* <!-- Nous recrutons Start --> */}
					<div className='mt-5 mb-5'>

						<Text1 text={text2} />

						<Button1 button={button} />

					</div>
					{/* <!-- Nous recrutons End --> */}

				</div>
			</div>
			<Footer />
		</>
	);
}

