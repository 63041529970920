import React from 'react';
import { Link } from 'react-router-dom';


// Layout
import Header from "../../layout/header/header";
import Footer from "../../layout/footer/footer3";

//Elements
import Banner from '../../elements/banner/banner-2';
import SEO from '../../elements/seo/seo';

// Icons
import { MdOutlineSubdirectoryArrowRight } from 'react-icons/md';
import { FiArrowRight } from 'react-icons/fi';


export default function YogNoteDeFrais() {
    return (
        <>
            <Header />

            {/* Dynamic Meta Tags */}
            <SEO
                title="YogNoteDeFrais - Application Mobile gestion de note de frais"
                description="Application mobile Android et IOS pour la gestion de vos notes de frais."
                canonical="yognotedefrais"
            />

            <Banner title="YogNoteDeFrais" entitled="Application mobile" navTitle="Accueil" navLink="/" />

            <div className="content-block" id="content-area">
                <div className="section-area section-sp1 bg-gray">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-6 mb-4">
                                <h2 className="text-capitalize">
                                    <a href="#" target="_blank" rel="noreferrer"> 📱 YogNoteDeFrais</a>
                                </h2>

                                <p className="fw-bold">
                                    Application mobile Android et IOS pour la gestion de vos notes de frais.
                                </p>
                                <p>
                                    YogNoteDeFrais est une application mobile pratique et facile à utiliser qui facilite la gestion de vos notes de frais.
                                    Elle vous permet de gagner du temps, de simplifier la gestion de vos dépenses et de garder une trace claire et précise de toutes vos dépenses.
                                </p>
                                <p>
                                    <MdOutlineSubdirectoryArrowRight /> Cette application a été conçue pour vous permettre de gérer facilement vos notes de frais, où que vous soyez et à tout moment.
                                </p>
                                <p className="fw-bold">Liste non-exaustive des fonctionnalités : </p>
                                <div className="mt-4 mb-4">
                                    <div className="row">
                                        <div className="col-6">
                                            <ul className="list-group" style={{ paddingLeft: "40px" }}>
                                                <li className="puce-change">Fonctionnelle hors ligne</li>
                                                <li className="puce-change">Données en temps réel</li>
                                                <li className="puce-change">Saisie des dépenses</li>
                                                <li className="puce-change">Suivi des dépenses</li>
                                            </ul>
                                        </div>
                                        <div className="col-6">
                                            <ul className="list-group" style={{ paddingLeft: "40px" }}>
                                                <li className="puce-change">Gestion des notes</li>
                                                <li className="puce-change">Exportation en zip</li>
                                                <li className="puce-change">Impression de PDF</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <h3 className='mt-5 h4'>Une solution simple et efficace</h3>
                                <p>
                                    Les fonctionnalités de l'application sont simples et efficaces. Vous pouvez saisir vos dépenses en seulement quelques clics,
                                    ce qui vous permet de gagner du temps et de simplifier la gestion de vos notes de frais.
                                    Grâce à l'application, vous pouvez également associer l'image du justificatif à la dépense, ce qui facilite la gestion
                                    et la conservation des preuves d'achat.
                                </p>
                                <p>
                                    En outre, YogNoteDeFrais vous permet de visualiser un pdf résumant toutes les informations relatives à la note de frais.
                                    Cela vous permet de garder une trace claire et précise de toutes vos dépenses et de faciliter la comptabilité de votre entreprise.
                                    Vous pouvez également consulter les dernières notes de frais enregistrées et suivre l'état d'importation de vos dépenses.
                                </p>
                                <p>
                                    L'application permet également de regrouper vos notes de frais par mois dans un fichier .zip bien ordonné que vous pouvez envoyer par mail.
                                    Cela simplifie la transmission de vos notes de frais à votre entreprise ou à votre comptable.
                                    Vous pouvez également transférer vos notes de frais d'une base de données locale à votre base de données externe.
                                </p>
                                <h3 className='mt-5 h4'>Conçu pour optimier la gestion de votre entreprise</h3>
                                <p>
                                    YogNoteDeFrais  vous permet de gagner du temps, de simplifier la gestion de
                                    vos dépenses et de garder une trace claire et précise de toutes vos dépenses.
                                </p>
                                <p>
                                    Demandez une démonstration en nous adressant une demande via nos <Link to="/contact">formulaires de contact</Link> en nous laissant vos coordonnées
                                    d’entreprise ou contactez-nous directement par téléphone au <a href="tel:0465230000">04 65 23 00 00</a>. Notre équipe fera suite à votre demande dans les plus brefs délais.
                                </p>
                                <span><FiArrowRight /><Link to="/politique-de-confidentialite-mobile" className='link-hover-primary'> Notre politique de confidentialité</Link></span>
                            </div>
                            <div className="text-center mt-3 mb-3">
                                <Link to="/contact" className="btn button-md rounded-pill">Demander une démo</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
