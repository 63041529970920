import React, { useState, useEffect } from 'react';
import axios from "axios";
import config from "../../../config.json";

// Layout
import Header from '../../layout/header/header';
import Footer from '../../layout/footer/footer3';

// Element
import Banner from '../../elements/banner/banner-2';
import Masonry from '../../elements/portfolio/masonry4';
import SEO from '../../elements/seo/seo';

// Text
import Text1 from '../../elements/text/text-1';

// Buttun 
import Button1 from '../../elements/button/button-1';

export default function NosSitesWeb() {

	// SETTER 
	const [meta, setMeta] = useState('')
	const [banner, setBanner] = useState('')
	const [data, setData] = useState('')

	useEffect(() => {
		axios.get(`${config.urlServer}api/sites`)
			.then(function (res) {
				setMeta(res.data)
				setBanner(res.data.Composents[0])
				setData(res.data.Composents[2])
			})
			.catch(err => { })
	}, []);

	// CONST 
	const metaData = {
		title: !meta.TitreMeta ? "Développeur de site web pour les  professionnels" : meta.TitreMeta,
		description: !meta.description ? "Développeur français indépendant d'applications et solution de gestion d'activités commerciales et associatives pour les professionnels." : meta.description,
		canonical: !meta.URLCanonical ? "sites" : meta.URLCanonical
	}

	const bannerContent = {
		title: !banner.Titre ? "Nos Réalisations" : banner.Titre,
		entitled: !banner.Corps ? "Sites Web" : banner.Corps
	}

	const text = {
		title: !data.Titre ? "Vous souhaitez également réaliser un site web ?" : data.Titre,
		p: !data.Corps ? "Prenez contact avec notre équipe de développeurs en remplissant notre formulaire pour découvrir la suite de nos réalisations." : data.Corps,
		alignText: "center",
		hsize: "28px"
	}

	const button = {
		BTNLib: !data.BTNLib ? "nous contacter" : data.BTNLib,
		BTNUrl: !data.BTNUrl ? "/contact" : data.BTNUrl,
		style: "center"
	}

	return (
		<>
			{/* Menu */}
			<Header />

			{/* Dynamic Meta Tags */}
			<SEO
				title={metaData.title}
				description={metaData.description}
				canonical={metaData.canonical}
			/>


			<Banner title={bannerContent.title} entitled={bannerContent.entitled} navTitle="Accueil" navLink="/" />

			<div className="page-content bg-grey">
				<div className="section-area section-sp2 bg-gray" id="content-area">
					<div className="container-max text-center mb-5">

						{/* <!-- Sites Start --> */}
						<Masonry />
						{/* <!-- Sites End --> */}

					</div>

					{/* <!-- Réaliser un site web Start --> */}
					<Text1 text={text} />
					<Button1 button={button} />
					{/* <!-- Réaliser un site web End --> */}

				</div>
			</div>

			<Footer />
		</>
	);
}
