import React from "react";

export default function ContactDetails(props) {
    return (
        <>
            <div className="row p-4">
                <div className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="icon-box">
                        <h5 className="title"><i className="ti-map-alt"></i>Localisation</h5>
                        {props.content.localisation &&
                            props.content.localisation.map((item) => (
                                <p>{item.Corps}</p>
                            ))
                        }
                        {props.err &&
                            props.static.map((item) => (
                                <p>{item.localisation}</p>
                            ))
                        }

                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp" data-wow-delay="0.6s">
                    <div className="icon-box">
                        <h5 className="title"><i className="ti-id-badge"></i>Email &amp; Téléphone</h5>
                        <p><a className="link-grey" href={props.content.emailUrl}>{props.content.emailLib}</a></p>
                        <p><a className="link-grey" href={props.content.telUrl}>{props.content.telLib}</a></p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp" data-wow-delay="0.9s">
                    <div className="icon-box">
                        <h5 className="title"><i className="ti-world "></i>Suivez-Nous</h5>
                        <ul className="list-inline ft-social-bx">
                            <li><a href={props.content.facebook} className="btn bluefacebook" target="_blank" rel="noreferrer"><i className="fa fa-facebook"></i></a></li>
                            <li><a href={props.content.twitter} className="btn bluetwitter" target="_blank" rel="noreferrer"><i className="fa fa-twitter"></i></a></li>
                            <li><a href={props.content.linkedin} className="btn bluelinkedin" target="_blank" rel="noreferrer"><i className="fa fa-linkedin"></i></a></li>
                            <li><a href={props.content.youtube} className="btn redyoutube" target="_blank" rel="noreferrer"><i className="fa fa-youtube-play"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}