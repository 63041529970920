import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import config from "../../config.json";

// Images
import IconImg1 from "../../images/icon/contact/phone.webp"
import IconImg2 from "../../images/icon/contact/email.webp"
import IconImg3 from "../../images/icon/contact/adresse.webp"
import Logo from "../../images/logo/logo2.webp"

export default function ContactSidebar() {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");
	const [sendMessage, setSendMessage] = useState("");

	useEffect(() => {
		// Contact Sidebar
		var asideBtn = document.querySelector(".slideinfo")
		var contactSidebar = document.querySelector(".contact-sidebar")
		var closeBtn = document.getElementById("clase-btn")

		asideBtn.addEventListener('click', function () {
			contactSidebar.classList.add("open")
		})

		closeBtn.addEventListener('click', function () {
			contactSidebar.classList.remove("open")
		})

	},[]);

	const clearState = () => {
		setName("")
		setEmail("")
		setMessage("")

	}

	const handlSubmit = (evt) => {
		evt.preventDefault();
		clearState();
		setSendMessage("Merci.")

		axios.post(`${config.urlServer}api/formdata`, {
			name: name,
			email: email,
			message: message
		})
			.then(function (response) {
				console.log(response);
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	// SETTER 
	const [tel, setTelephone] = useState('')
	const [mail, setMail] = useState('')
	const [adresse, setAdresse] = useState('')

	useEffect(() => {
		axios.get(`${config.urlServer}api/accueil`)
			.then(function (res) {
				setTelephone(res.data.Composents[0].Data[0].Corps)
				setMail(res.data.Composents[0].Data[1].Corps)
				setAdresse(res.data.Composents[0].Data[2].Corps)
			})
	},[]);

	// CONST 
	const content = {
		phone: !tel ? "04 65 23 00 00" : tel,
		email: !mail ? "contact@yogan.fr" : mail,
		adresse: !adresse ? "Paris - Aix en Provence" : adresse
	}

	return (
		<>
			<div className="slideinfo">
				<ul className="slide-social">
					<li>Social Link</li>
					<li className="sc-link"><a href={"https://www.facebook.com/yogansoftware/"} target="_blank" rel="noreferrer"><i className="fa fa-facebook"></i></a></li>
					<li className="sc-link"><a href={"https://mobile.twitter.com/yogansoftware"} target="_blank" ><i className="fa fa-twitter"></i></a></li>
					<li className="sc-link"><a href={"https://www.linkedin.com/company/yogan/"} target="_blank" ><i className="fa fa-linkedin"></i></a></li>
					<li className="sc-link"><a href={"https://www.youtube.com/channel/UC2qrLXlbOajb6NRCdhBZlxg/videos?app=desktop"} target="_blank" ><i className="fa fa-youtube-play"></i></a></li>
				</ul>
				<Link to={"#"} className="slider-contact btn contact-sidebtn">Nous contacter</Link>
			</div>
			<div className="contact-sidebar content-scroll">
				<div className="inner">
					<div className="logo">
						<img src={Logo} style={{
							height: "40px"
						}} alt="Yogan Développement - logo d'entreprise" />
					</div>
					<h2 className="title fw-bold text-capitalize h4">Nos Coordonnées</h2>
					<ul className="contact-infolist">
						<li>
							<img src={IconImg1} style={{
								width: "30px"
							}} alt="Yogan Développement - icone" />
							<h3 className="contact-title">Téléphone</h3>
							<p>{content.phone}</p>
						</li>
						<li>
							<img src={IconImg2} style={{
								width: "30px"
							}} alt="Yogan Développement - icone" />
							<h3 className="contact-title">Email</h3>
							<Link to="#">{content.email}</Link>
						</li>
						<li>
							<img src={IconImg3} style={{
								width: "30px"
							}} alt="Yogan Développement - icone" />
							<h3 className="contact-title">Adresse</h3>
							<p>{content.adresse}</p>
						</li>
					</ul>
					<h4 className="title text-capitalize">Demande d'information</h4>
					<form className="contact-bx ajax-form" onSubmit={handlSubmit}>
						<div className="ajax-message"></div>
						<div className="row placeani">
							<div className="col-lg-12">
								<div className="form-group">
									<div className="input-group">
										{/* <label>Votre Nom</label> */}
										<input name="name" type="text" className="form-control" value={name} onChange={e => setName(e.target.value)} placeholder='Nom' required />
									</div>
								</div>
							</div>
							<div className="col-lg-12">
								<div className="form-group">
									<div className="input-group">
										{/* <label>Votre Adresse Mail</label> */}
										<input name="email" type="email" className="form-control" value={email} onChange={e => setEmail(e.target.value)} placeholder='Email' required />
									</div>
								</div>
							</div>
							<div className="col-lg-12">
								<div className="form-group">
									<div className="input-group">
										{/* <label>Message</label> */}
										<textarea name="message" rows="4" className="form-control" value={message} onChange={e => setMessage(e.target.value)} placeholder='Message' required ></textarea>
									</div>
								</div>
							</div>
							{
								sendMessage &&
								<div className='mb-3'>
									<span className='text-primary-style' value={sendMessage} onChange={e => setSendMessage(e.target.value)}>{sendMessage}</span>
								</div>

							}
							<div className="col-lg-12">
								<button name="submit" type="submit" valueDefault="Submit" className="btn button-md">Envoyer</button>
							</div>
						</div>
					</form>
				</div>
			</div>
			<div className="contact-sidebtn close" id="clase-btn">
				<i className="ti-close"></i>
			</div>
		</>
	);

}

