import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

// Get Api
import axios from "axios";
import config from "../../../config.json";

// Markdown 
// import ReactMarkdown from "react-markdown";

export default function Footer3() {

	// SETTER 
	const [data1, setData1] = useState('')
	const [adresse, setAdresse] = useState('')
	const [email, setEmail] = useState('')
	const [phone, setPhone] = useState('')
	const [data2, setData2] = useState('')
	const [data3, setData3] = useState('')
	const [data4, setData4] = useState('')
	const [data5, setData5] = useState('')
	const [data6, setData6] = useState('')

	// Manage static data 
	const [err, setErr] = useState(false)

	useEffect(() => {
		axios.get(`${config.urlServer}api/footer`)
			.then(function (res) {

				// Nos Coordonnées
				setData1(res.data.Composents[0])
				setAdresse(res.data.Composents[0].Data[0])
				setEmail(res.data.Composents[0].Data[1])
				setPhone(res.data.Composents[0].Data[2])

				// Développement
				setData2(res.data.Composents[1])

				// Produits
				setData3(res.data.Composents[2])

				// Actualités
				setData4(res.data.Composents[3])

				// Suivez-nous
				setData5(res.data.Composents[4])

				// Footer bottom
				setData6(res.data.Composents[5])

			})
			.catch(err => {
				setErr(true)
			})
	}, []);


	// CONST 
	const coordonnes = {
		adresse: !adresse.Corps ? "4 Avenue de l'Opéra, 75001 Paris" : adresse.Corps,
		email: !email.Corps ? "contact@yogan.fr" : email.Corps,
		phone: !phone.Corps ? "04 65 23 00 00" : phone.Corps,
	}

	const titre = {
		t1: !data1.Titre ? "Nos coordonnées" : data1.Titre,
		t2: !data2.Titre ? "Développement" : data2.Titre,
		t3: !data3.Titre ? "Produits" : data3.Titre,
		t4: !data4.Titre ? "Actualités" : data4.Titre,
		t5: !data5.Titre ? "Suivez-nous" : data5.Titre
	};

	const copyrightContent = {
		lib: !data6.BTNLib ? "©" : data6.BTNLib,
		url: !data6.BTNUrl ? "/copyright" : data6.BTNUrl
	}

	return (
		<>
			<footer className="footer-style2">
				<div className="footer-top bt0">
					<div className="container">
						<div className="row">
							<div className="col-6 col-lg-3 col-md-2 col-sm-6">
								<div className="widget widget_info">
									<h2 className="footer-title h5">{titre.t1}</h2>
									<ul>
										{/* <li><ReactMarkdown className='markdown-adresse'>{coordonnes.adresse}</ReactMarkdown></li> */}
										<li>4 Avenue de l'Opéra,<br /> 75001 Paris</li>
										<li>270 Rue François Gernelle,<br /> 84120 Pertuis</li>

										<li><a className='link-footer-unset' href={email.BTNUrl}>{coordonnes.email}</a></li>
										<li><a className='link-footer-unset' href={phone.BTNUrl}>{coordonnes.phone}</a></li>
									</ul>
								</div>
							</div>
							<div className="col-12 col-lg-3 col-md-3 col-sm-6">
								<div className="widget footer_widget">
									<h2 className="footer-title h5">{titre.t2}</h2>
									<ul>
										{
											data2.Data &&
											data2.Data.map((item) => (
												<li key={item.ID}><Link to={item.BTNUrl}>{item.BTNLib}</Link></li>
											))
										}
									</ul>
									{
										err &&
										<ul>
											<li><Link to="/logiciels">Logiciels</Link></li>
											<li><Link to="/web" >Web</Link></li>
											<li><Link to="/support">Support</Link></li>
											<li><Link to="/sites">Nos sites</Link></li>
											<li><Link to="/equipe-yogan-developpement">Notre Équipe</Link></li>
											{/* <li><Link to="/partenaires">Nos Partenaires</Link></li> */}
										</ul>
									}
								</div>
							</div>
							<div className="col-12 col-lg-3 col-md-2 col-sm-6">
								<div className="widget footer_widget">
									<h2 className="footer-title h5">{titre.t3}</h2>
									<ul>
										{
											data3.Data &&
											data3.Data.map((item) => (
												<li key={item.ID}><a href={item.BTNUrl}>{item.BTNLib}</a></li>
											))
										}
									</ul>
									{
										err &&
										<ul>
											<li><a href="https://play.google.com/store/apps/details?id=fr.yogan.infinity" target="_blank" rel="noreferrer">Infinity</a></li>
											<li><a href="https://www.yogshow.fr/" target="_blank" rel="noreferrer">YogShow</a></li>
											<li><a href="https://www.animationduvoisinage.fr/" target="_blank" rel="noreferrer">VieLocale</a></li>
											<li><Link to="/yognotedefrais">YogNoteDeFrais</Link></li>
											{/* <li><a href="https://www.yogplanningime.fr/" target="_blank" rel="noreferrer">YogPlanningIME</a></li> */}
											<li><Link to="/connecteurs-api">Connecteurs d'API </Link></li>
										</ul>
									}
								</div>
							</div>
							<div className="col-12 col-lg-3 col-md-5 col-sm-6 footer-col-4">
								<div className="widget footer_widget">
									<h2 className="footer-title h5">{titre.t4}</h2>
									<ul>
										{
											data4.Data &&
											data4.Data.map((item) => (
												<li key={item.ID}><Link to={item.BTNUrl}>{item.BTNLib}</Link></li>
											))
										}
									</ul>
									{
										err &&
										<ul>
											<li><Link to="#">Blogs</Link></li>
											<li><Link to="/nos-references">Références</Link></li>
											<li><Link to="/recrutement">Recrutement</Link></li>
										</ul>
									}
								</div>
								<div className="widget">
									<h2 className="footer-title h5">{titre.t5}</h2>
									<ul className="list-inline ft-social-bx">
										{
											data5.Data &&
											data5.Data.map((item) => (
												<li key={item.ID}><a className="btn" href={item.BTNUrl} aria-label={item.Corps} target="_blank" rel="noreferrer"><i className={item.BTNLib}></i></a></li>
											))
										}
									</ul>
									{
										err &&
										<ul className="list-inline ft-social-bx">
											<li><a className="btn" href={"https://www.facebook.com/yogansoftware/"} aria-label="Facebook Yogan Développement" target="_blank" rel="noreferrer"><i className="fa fa-facebook"></i></a></li>
											<li><a className="btn" href={"https://mobile.twitter.com/yogansoftware"} aria-label="Twitter Yogan Développement" target="_blank" rel="noreferrer"><i className="fa fa-twitter"></i></a></li>
											<li><a className="btn" href={"https://es.linkedin.com/company/yogan"} aria-label="Linkedin Yogan Développement" target="_blank" rel="noreferrer"><i className="fa fa-linkedin"></i></a></li>
											<li><a className="btn" href={"https://www.youtube.com/channel/UC2qrLXlbOajb6NRCdhBZlxg/videos?app=desktop&cbrd=1"} aria-label="Youtube Yogan Développement" target="_blank" rel="noreferrer"><i className="fa fa-youtube-play"></i></a></li>
										</ul>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="footer-bottom">
					<div className="container">
						<div className="row column-reverse footer-border-top">
							<div className="col-lg-6 col-md-6 col-sm-12 text-center text-md-start">
								<p className="m-b0" style={{ color: "#efebeb" }}><Link to={copyrightContent.url} className="link-footer-white">{copyrightContent.lib}</Link> {new Date().getFullYear()} <Link to="/" className="link-footer-unset">Yogan Développement</Link></p>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12 text-center text-md-end">
								<ul className="link-inline">
									{
										data6.Data &&
										data6.Data.map((item) => (
											<li key={item.ID}><Link to={item.BTNUrl}>{item.BTNLib}</Link></li>
										))
									}
								</ul>
								{
									err &&
									<ul className="link-inline">
										<li><Link to="/plan-de-site">Plan du site</Link></li>
										<li><Link to="/securite">Sécurité</Link></li>
										<li><Link to="/mentions-legales">Mentions légales</Link></li>
										<li><Link to="/politique-de-confidentialite">Politique de confidentialité</Link></li>
									</ul>
								}
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
}