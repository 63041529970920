import React, { useState, useEffect } from 'react';

// Get Api
import axios from "axios";
import config from "../../../config.json";

// Markdown 
import ReactMarkdown from "react-markdown";

// Layout
import Header from "../../layout/header/header";
import Footer from "../../layout/footer/footer3";

//Element
import Banner from '../../elements/banner/banner-2';
import SEO from '../../elements/seo/seo';

// List 
import List5 from '../../elements/list/list-5';

// Title 
import Title1 from '../../elements/title/title-1';

// Buttun 
import Button1 from '../../elements/button/button-1';

export default function ConnecteursAPI() {

	// SETTER 
	const [meta, setMeta] = useState('')
	const [banner, setBanner] = useState('')
	const [content1, setContent1] = useState('')
	const [content2, setContent2] = useState('')
	const [content3, setContent3] = useState('')
	const [list, setList] = useState('')

	// Manage static data 
	const [err, setErr] = useState(false)

	useEffect(() => {
		axios.get(`${config.urlServer}api/connecteurs-api`)
			.then(function (res) {
				setMeta(res)
				setBanner(res.data.Composents[0])
				setContent1(res.data.Composents[1])
				setContent2(res.data.Composents[2])
				setList(res.data.Composents[3].Data)
				setContent3(res.data.Composents[4])
			})
			.catch(err => {
				setErr(true)
			})
	}, []);

	// CONST 
	const metaData = {
		title: !meta.TitreMeta ? "Connecteurs d'API Kompass pour les environnements WinDev" : meta.TitreMeta,
		description: !meta.description ? "Connecteurs d'API Kompass à déployer sur vos environnements WINDEV, WEBDEV et WINDEV Mobile pour optimiser l'utilisation de vos systèmes d'informations (PC SOFT)." : meta.description,
		canonical: !meta.URLCanonical ? "connecteurs-api" : meta.URLCanonical
	}

	const bannerContent = {
		title: !banner.Titre ? "Connecteurs D'API" : banner.Titre,
		entitled: !banner.Corps ? "Api" : banner.Corps
	}

	const content = {
		t1: !content1.Titre ? "Connecteurs D’API" : content1.Titre,
		cp1: !content1.Corps ? "Connectez aisément différents systèmes d’informations dans vos environnements de développement WinDev avec les solutions de Yogan Développement." : content1.Corps,
		t2: !content2.Titre ? "Connecteur Kompass" : content2.Titre,
		cp2: !content2.Corps ? "C’est un produit livré clé en main prêt à être déployé sur vos projets WinDev pour vous permettre de bénéficier de l’exhaustivité des Données Entreprises de Kompass." : content2.Corps,
		t3: !content3.Titre ? "Nos garanties clients" : content3.Titre,
		cp3: !content3.Corps ? "Yogan Développement vous garantit une mise en œuvre facile avec un produit prêt à l’emploi, directement prêt à être implémenté par vos développeurs. Nous garantissons également un suivi auprès de vos équipes pour la mise en place de la solution Kompass au sein de votre entreprise." : content3.Corps,

	}

	const button = {
		BTNLib: !content3.BTNLib ? "Demander une démo" : content3.BTNLib,
		BTNUrl: !content3.BTNUrl ? "/contact" : content3.BTNUrl,
		style: "center"
	}

	// STATIC DATA
	const listStatic = [
		{
			Corps: "Recherche ciblée par métier (type de compagnie) pour orienter directement vos recherches sur des secteurs métiers."
		},
		{
			Corps: "Filtrer par Zone géographique, pour orienter votre recherche sur une région ou une ville spécifique."
		},
		{
			Corps: "Utiliser l'auto-complétion pour automatiquement préremplir vos champs avec les recherches communes les plus associées."
		}
	]

	return (
		<>
			{/* Menu */}
			<Header />

			{/* Dynamic Meta Tags */}
			<SEO
				title={metaData.title}
				description={metaData.description}
				canonical={metaData.canonical}
			/>

			<Banner title={bannerContent.title} entitled={bannerContent.entitled} navTitle="Accueil" navLink="/" />

			<div className="content-block" id="content-area">
				<div className="section-area section-sp1 bg-gray">
					<div className="container">
						<div className="row">
							<div className="col-lg-12 col-md-6 mb-4">

								{/* <!-- Connecteurs d'api Start --> */}
								<Title1 title={content.t1} textTransform={"capitalize"} />
								<ReactMarkdown className='mb-5'>{content.cp1}</ReactMarkdown>
								{/* <!-- Connecteurs d'api End --> */}


								{/* <!-- Connecteurs Kompass Start --> */}
								<Title1 title={content.t2} fontSize={"calc(1.275rem + .3vw)"} fontWeight={"unset"} textDecoration={"underline"} textTransform={"capitalize"} />
								<ReactMarkdown className='mb-5'>{content.cp2}</ReactMarkdown>								{/* <!-- Connecteurs Kompass --> */}
								{/* <!-- Connecteurs Kompass End --> */}


								{/* <!-- Liste Start --> */}
								<List5 list={list} static={listStatic} err={err} /><br />
								{/* <!-- Liste End --> */}

								{/* <!-- Garanties clients Start --> */}
								<Title1 title={content.t3} fontSize={"calc(1.275rem + .3vw)"} fontWeight={"unset"} textDecoration={"underline"} textTransform={"capitalize"} />
								<ReactMarkdown>{content.cp3}</ReactMarkdown>
								{/* <!-- Garanties clients End --> */}

								<Button1 button={button} />

							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}