import React, { useState, useEffect } from 'react';
import axios from "axios";
import config from "../../../config.json";

// Layout
import Header from '../../layout/header/header';
import Footer from '../../layout/footer/footer3';

// Elements
import Banner from '../../elements/banner/banner-2';
import Masonry from "../../elements/portfolio/masonry6";
import SEO from '../../elements/seo/seo';

// Text
import Text1 from '../../elements/text/text-1';

// Buttun 
import Button1 from '../../elements/button/button-1';

export default function References() {

	// SETTER 
	const [meta, setMeta] = useState('')
	const [data, setData] = useState('')
	const [pExtra, setPExtra] = useState('')
	const [banner, setBanner] = useState('')

	// CARDS 
	const [data1, setData1] = useState('')
	const [data2, setData2] = useState('')
	const [data3, setData3] = useState('')

	useEffect(() => {
		axios.get(`${config.urlServer}api/ref`)
			.then(function (res) {
				setMeta(res.data)
				setBanner(res.data.Composents[0])
				setData(res.data.Composents[1])
				setPExtra(res.data.Composents[0].Data[0].Corps)
				setData1(res.data.Composents[1].Data[1])
				setData2(res.data.Composents[1].Data[2])
				setData3(res.data.Composents[1].Data[3])
			})
	},[]);

	// CONST 
	const metaData = {
		title: !meta.TitreMeta ? "Nos références : utilisez leurs expériences pour faciliter votre expérience" : meta.TitreMeta,
		description: !meta.description ? "Développeur français indépendant d'applications et solution de gestion d'activités commerciales et associatives pour les professionnels." : meta.description,
		canonical: !meta.URLCanonical ? "nos-references" : meta.URLCanonical
	}

	const bannerContent = {
		bannerTitle: !banner.Titre ? "Nos Références" : banner.Titre,
		bannerEntitled: !banner.Corps ? "Références" : banner.Corps
	}

	const text = {
		title: !data.Titre ? "Nos projets Clients" : data.Titre,
		p: !data.Corps ? "Utilisez leurs expériences pour faciliter votre expérience !" : data.Corps,
		alignText: "center",
		h: "capitalize"
	}

	const button = {
		BTNLib: !data.BTNLib ? "développer un projet" : data.BTNLib,
		BTNUrl: !data.BTNUrl ? "/contact" : data.BTNUrl,
		style: "center"
	}

	const text2 = {
		title: "",
		p: !pExtra ? "Contactez-nous pour découvrir le reste de nos cas clients." : pExtra,
		alignText: "center",
		h: "italic"
	}

	return (
		<>
			{/* Menu */}
			<Header />

			{/* Dynamic Meta Tags */}
			<SEO
				title={metaData.title}
				description={metaData.description}
				canonical={metaData.canonical}
			/>

			<Banner title={bannerContent.bannerTitle} entitled={bannerContent.bannerEntitled} navTitle="Accueil" navLink="/" />

			<div className="content-block" id="content-area">
				<div className="section-area section-sp1 bg-gray">

					{/* <!-- Nos projets clients Start --> */}
					<div style={{ marginBottom: "5%" }}>
						<Text1 text={text} />
					</div>
					{/* <!-- Nos projets clients End --> */}

					{/* <!-- Logo client Start --> */}
					<div className="container mt-2">
						<Masonry data1={data1} data2={data2} data3={data3} />
					</div>
					{/* <!-- Logo client End --> */}

					<div className="mb-5" style={{ marginTop: "5%" }}>
						<Button1 button={button} />
					</div>

					{/* <!-- Contactez-nous reste cas client Start --> */}
					<i><Text1 text={text2} /></i>
					{/* <!-- Contactez-nous reste cas client End --> */}

				</div>
			</div>

			<Footer />
		</>
	);
}