import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

// Get Api
import axios from "axios";
import config from "../../../config.json";

// Markdown 
import ReactMarkdown from "react-markdown";

// Layout
import Header from "../../layout/header/header";
import Footer from "../../layout/footer/footer3";

// Element
import Banner from '../../elements/banner/banner-1';
import SEO from '../../elements/seo/seo';

export default function PolitiqueDeConfidentialite() {

	// SETTER
	const [data, setData] = useState('')

	// Manage static data 
	const [err, setErr] = useState(false)

	useEffect(() => {
		axios.get(`${config.urlServer}api/politique-de-confidentialite`)
			.then(function (res) {
				setData(res.data)
			})
			.catch(err => {
				setErr(true)
			})
	},[]);

	// CONST 
	const metaData = {
		title: !data.TitreMeta ? "Cookies et politique de confidentialité Yogan Développement" : data.TitreMeta,
		description: !data.DescriptionMeta ? "Cookies et politique de confidentialité sur le site www.yogan.fr." : data.DescriptionMeta,
		canonical: !data.Canonical ? "politique-de-confidentialite" : data.Canonical
	}

	const bannerContent = {
		title: !data.TitreBanner ? "Politique de confidentialité" : data.TitreBanner,
	}

	return (

		<>
			{/* Menu */}
			<Header />

			{/* Dynamic Meta Tags */}
			<SEO
				title={metaData.title}
				description={metaData.description}
				canonical={metaData.canonical}
			/>

			<Banner title={bannerContent.title} entitled={bannerContent.title} navTitle="Accueil" navLink="/" />

			<div className="section-area section-sp2" id="content-area">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-6 p-4">

							{/* <!-- Intro Start --> */}
							{
								data &&
								<div style={{ marginBottom: "55px" }}>
									<ReactMarkdown className='text-center'>{data.Intro}</ReactMarkdown>
								</div>
							}
							{
								err && // static data
								<div className="text-center mb-5">
									<h3>Données Personnelles</h3>
									<h4>La sécurité de vos données est importante pour nous !</h4>
									<p>Yogan s'engage à ce que <b>la collecte et le traitement de vos données</b>, effectués à partir du site <Link to="/" className='link-hover-primary'>www.yogan.fr</Link>, soient <b> conformes au règlement général sur la protection des données (RGPD) et à la loi informatique et Libertés.</b></p>
								</div>
							}
							{/* <!-- Intro End --> */}


							{/* <!-- Contenu Start --> */}
							{
								data &&
								<ReactMarkdown className='mt-5'>{data.Corps}</ReactMarkdown>
							}
							{
								err &&
								<div>
									<p className="fw-bold">Chaque formulaire ou téléservice limite la collecte des données personnelles au strict nécessaire (minimisation des données) et indique notamment :</p>
									<div className="mb-5">
										<ul>
											<li className="mb-3">Quels sont les objectifs du recueil de ces données (finalités);</li>
											<li className="mb-3">Si ces données sont obligatoires ou facultatives pour la gestion de votre demande;</li>
											<li className="mb-3"><span>Qui pourra en prendre connaissance (uniquement Yogan en principe, sauf précision dans le formulaire lorsqu’une transmission à un tiers est nécessaire à la gestion de votre demande);</span></li>
											<li className="mb-3">Quels sont les objectifs du recueil de ces données (finalités);</li>
											<li className="mb-3"><a href="https://www.cnil.fr/fr/les-droits-pour-maitriser-vos-donnees-personnelles" target="_blank" rel="noreferrer">Vos droits Informatique et Libertés</a> et la façon de les exercer auprès de Yogan.</li>
										</ul>
										<div>
											<p>
												Les données personnelles recueillies dans le cadre des services proposés sur <Link to="/" className='link-hover-primary'>www.yogan.fr</Link> sont traitées selon des protocoles sécurisés et permettent à Yogan de gérer les demandes reçues dans ses applications informatiques.
												Pour toute information ou exercice de vos droits Informatique et Libertés sur les traitements de données personnelles gérés par Yogan, vous pouvez contacter son délégué à la protection des données (DPO) :
											</p>
										</div>
									</div>

									<div className="mb-5">
										<ul>
											<li className="puce-change mb-3"><Link to="/contact">Par ce formulaire</Link></li>
											<li className="puce-change mb-3">Ou par courrier signé accompagné de la copie d’un titre d’identité à l’adresse suivante : <br />Yogan 4 Avenue de l'Opéra, 75001 Paris (France).</li>
										</ul>
									</div>
									<div>
										<h5>À propos des cookies</h5>
										<p>Nous utilisons différents cookies sur le site pour améliorer l’interactivité du site et  nos services.</p>
										<p className="fw-bold">Qu’est-ce qu’un « cookie » ?</p>
										<p>Un « cookie » est un fichier de taille limitée, généralement constitué de lettres et de chiffres, envoyé par le serveur internet au fichier cookie du navigateur situé sur le disque dur de votre ordinateur.</p>
										<p><a href="https://www.cnil.fr/fr/cookies-et-autres-traceurs/comment-se-proteger/maitriser-votre-navigateur" target="_blank" rel="noreferrer">En savoir plus sur les cookies, leur fonctionnement et les moyens de s'y opposer.</a></p>
									</div>
									<div>
										<h6>Cookies internes nécessaires au site pour fonctionner</h6>
										<p>Ces cookies permettent au site de fonctionner de manière optimale. Vous pouvez vous y opposer et les supprimer en utilisant les paramètres de votre navigateur, cependant votre expérience utilisateur risque d’être dégradée.<br />Nous collectons également votre adresse IP, afin de déterminer la ville depuis laquelle vous vous connectez. Celle-ci est immédiatement anonymisée après utilisation. Yogan ne peut donc en aucun cas remonter par ce biais à une personne physique.<br />Les données personnelles recueillies (identifiant du cookie) sont conservées par Yogan pour une durée de 6 mois . Elles ne sont pas cédées à des tiers ni utilisées à d’autres fins.
											Vous pouvez choisir de refuser les cookies et l’analyse statistique de vos données de navigation.</p>
									</div>
									<div className="mb-5">
										<h6>Cookie tiers destinés à améliorer l’interactivité du site</h6>
										<p>Le site de Yogan s’appuie sur certains services proposés par des sites tiers. Il s’agit notamment :</p>
										<ul>
											<li className="mb-2">Des boutons de partage (<a href="https://mobile.twitter.com/yogansoftware" className='link-hover-primary' target="_blank" rel="noreferrer">Twitter</a>)</li>
											<li className="mb-2">Des listes de tweets (<a href="https://mobile.twitter.com/yogansoftware" className='link-hover-primary' target="_blank" rel="noreferrer">Twitter</a>)</li>
											<li className="mb-2">Des boutons de partage (<a href="https://www.linkedin.com/company/yogan/?originalSubdomain=fr" className='link-hover-primary' target="_blank" rel="noreferrer">Linkedin</a>)</li>
											<li className="mb-2">Des boutons de partage (<a href="https://www.facebook.com/yogansoftware/" className='link-hover-primary' target="_blank" rel="noreferrer">Facebook</a>)</li>
											<li className="mb-2">Des vidéos diffusées sur le site (<a href="https://www.youtube.com/channel/UC2qrLXlbOajb6NRCdhBZlxg/videos?app=desktop" className='link-hover-primary' target="_blank" rel="noreferrer">Youtube</a> )</li>
										</ul>
										<p> Ces fonctionnalités utilisent des cookies tiers directement déposés par ces services. Lors de votre première visite sur
											<Link to="/" className='link-hover-primary'> yogan.fr</Link>, un bandeau vous informe de la présence de ces cookies et vous invite à indiquer votre choix. Ils ne sont déposés que si vous les acceptez.
										</p>
									</div>
									<div className="mb-5">
										<h5>Durée de conservation des données</h5>
										<p className="fw-bold">Concernant les données relatives à la gestion de clients et prospect :</p>
										<p>
											Vos données à caractère personnel ne seront pas conservées au-delà de la durée strictement nécessaire à la gestion de notre relation commerciale avec vous. Toutefois, les données permettant d’établir la preuve d’un droit ou d’un contrat, devant être conservées au titre du respect d’une obligation légale, le seront pendant la durée prévue par la loi en vigueur.
										</p>
										<p>
											Concernant d’éventuelles opérations de prospection à destination des clients, leurs données pourront être conservées pendant un délai de trois ans à compter de la fin de la relation commerciale.
										</p>
										<p>
											Les données à caractère personnel relatives à un prospect, non client, pourront être conservées pendant un délai de trois ans à compter de leur collecte ou du dernier contact émanant du prospect.
										</p>
										<p>
											Au terme de ce délai de trois ans, nous pourrons reprendre contact avec vous pour savoir si vous souhaitez continuer à recevoir des sollicitations commerciales.
										</p>

										<p className="fw-bold">Concernant les pièces d’identité :</p>
										<p>
											En cas d’exercice du droit d’accès ou de rectification, les données relatives aux pièces d’identité pourront être conservées pendant le délai prévu à l’article 9 du code de procédure pénale, soit un an. En cas d’exercice du droit d’opposition, ces données peuvent être archivées pendant le délai de prescription prévu par l’article 8 du code de procédure pénale, soit trois ans.
										</p>

										<p className="fw-bold">Concernant la gestion des listes d’opposition à recevoir de la prospection :</p>
										<p>
											Les informations permettant de prendre en compte votre droit d’opposition sont conservées au minimum trois ans à compter de l’exercice du droit d’opposition.
										</p>

										<p className="fw-bold">Concernant les statistiques de mesure d’audience :</p>
										<p>Les informations stockées dans le terminal des utilisateurs ou tout autre élément utilisé pour identifier les utilisateurs et permettant leur traçabilité ou fréquentation ne seront pas conservées au-delà de 6 mois.
										</p>
									</div>
								</div>
							}
							{/* <!-- Contenu End --> */}


						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
}
