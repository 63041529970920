import React, { useState } from 'react';
import axios from "axios";
import config from '../../../config.json';

// Element
import Banner from '../../elements/banner/banner-3';
import Modal from '../../elements/modal/modal-1';

// Text
import Text1 from '../../elements/text/text-1';

// SEO
import SEO from '../../elements/seo/seo';

export default function ErrorModelIIS() {

	// CONFIG MODAL FORM CONTACT -------------------------------------->
	const [openModal, setOpenModal] = useState(false);

	const design = {
		withModal: '380px',
		heightModal: '273px',
		sizeButton: '37%',
		sizeLogo: '50%',
		crossToClose: 'X'

	};
	const text = {
		p: "Votre message a bien été envoyé !",
		textButton: "Ok"
	};

	// DATA FORM ------------------------------------------------------>
	const [name, setName] = useState("");
	const [firstname, setFirstname] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [message, setMessage] = useState("");

	const clearState = () => {
		setName("")
		setFirstname("")
		setEmail("")
		setPhone("")
		setMessage("")
	};

	const HandlSubmit = (evt) => {
		evt.preventDefault();
		clearState();
		setOpenModal(true);

		axios.post(`${config.urlServer}api/formdata`, {
			name: name,
			firstname: firstname,
			email: email,
			phone: phone,
			message: message
		})
			.then(function (response) {
				console.log(response);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	// CONST 
	const bannerContent = {
		title: "La page demandée est introuvable",
		entitled: "",
		texte: "Vous pouvez retourner sur la page d'acceuil :",
		texte2: "ou nous contacter via notre formulaire de contact ci-dessous : "
	}

	const text1 = {
		title: "Nos coordonnées",
		h: "capitalize"
	}
	const text2 = {
		title: "Nous contacter",
		p: "Utilisez le formulaire ci-dessous pour nous contacter (Veillez à utiliser votre mail client) :",
		h: "capitalize",
		psize: "18px"
	}

	return (
		<>
			{/* Dynamic Meta Tags */}
			<SEO
				title="Erreur-404"
				description="Page non trouvée"
				canonical="404"
			/>
			<Banner title={bannerContent.title} texte={bannerContent.texte} entitled={bannerContent.entitled} navTitle="Accueil" navLink="/" texte2={bannerContent.texte2} />

			{/* if openModal == true then we runing Modal component  */}
			{openModal && <Modal setOpenModal={setOpenModal} design={design} text={text} />}

			<div className="section-area section-sp1" id="content-area">
				<div className="container">
					<Text1 text={text1} />
					<div className="row p-4">
						<div className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp" data-wow-delay="0.3s">
							<div className="icon-box">
								<h5 className="title"><i className="ti-map-alt"></i>Localisation</h5>
								<p>270 Rue François Gernelle, 84120 Pertuis</p>
								<p>4 Avenue de l'Opéra, 75001 Paris</p>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp" data-wow-delay="0.6s">
							<div className="icon-box">
								<h5 className="title"><i className="ti-id-badge"></i>Email &amp; Téléphone</h5>
								<p><a className="link-grey" href="mailto:contact@yogan.fr">contact@yogan.fr</a></p>
								<p><a className="link-grey" href="tel:0465230000">04 65 23 00 00</a></p>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 wow fadeInUp" data-wow-delay="0.9s">
							<div className="icon-box">
								<h5 className="title"><i className="ti-world "></i>Suivez-Nous</h5>
								<ul className="list-inline ft-social-bx">
									<li><a href="" className="btn bluefacebook" target="_blank" rel="noreferrer"><i className="fa fa-facebook"></i></a></li>
									<li><a href="" className="btn bluetwitter" target="_blank" rel="noreferrer"><i className="fa fa-twitter"></i></a></li>
									<li><a href="" className="btn bluelinkedin" target="_blank" rel="noreferrer"><i className="fa fa-linkedin"></i></a></li>
									<li><a href="" className="btn redyoutube" target="_blank" rel="noreferrer"><i className="fa fa-youtube-play"></i></a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="section-area mb-5">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 wow fadeIn" data-wow-delay="0.4s">
							<form className="contact-bx ajax-form" method="post" onSubmit={HandlSubmit}>
								<div className="ajax-message"></div>
								<Text1 text={text2} />
								<div className="row placeani">
									<div className="col-lg-3">
										<div className="form-group">
											<div className="input-group">
												<input name="name" type="text" className="form-control valid-character" placeholder="Votre Nom" value={name} onChange={e => setName(e.target.value)} required />
											</div>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="form-group">
											<div className="input-group">
												<input name="name" type="text" className="form-control valid-character" placeholder="Votre Prénom" value={firstname} onChange={e => setFirstname(e.target.value)} required />
											</div>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="form-group">
											<div className="input-group">
												<input name="email" type="email" className="form-control" placeholder="Votre Email" value={email} onChange={e => setEmail(e.target.value)} required />
											</div>
										</div>
									</div>
									<div className="col-lg-3">
										<div className="form-group">
											<div className="input-group">
												<input name="phone" type="text" className="form-control int-value" placeholder="Votre Téléphone" value={phone} onChange={e => setPhone(e.target.value)} required />
											</div>
										</div>
									</div>
									<div className="col-lg-12">
										<div className="form-group">
											<div className="input-group">
												<textarea name="message" rows="4" className="form-control" placeholder="Votre Demande" value={message} onChange={e => setMessage(e.target.value)} required></textarea>
											</div>
										</div>
									</div>
									<div className="col-lg-12 mb-5">
										<button name="submit" type="submit" value="Submit" className="btn button-md">Envoyer</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}


