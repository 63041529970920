import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import config from "../../../config.json";

// Picture 
import PortImgHp from './../../../images/sites/hp.webp';
import PortImgWebere from './../../../images/sites/webere.webp';
import PortImgSifrein from './../../../images/sites/sifrein.webp';
import PortImgPellenc from './../../../images/sites/pellenc.webp';
import PortImgDivin from './../../../images/sites/divin-poulet.webp';
import PortImgEdilson from './../../../images/sites/edilson-show.webp';
import PortImgSafran from './../../../images/sites/safran.webp';
import PortImgCde from './../../../images/sites/cde.webp';
import PortImgGcp from './../../../images/sites/cgp.webp';
import PortImgMotorhell from './../../../images/sites/motorhell.webp';
import PortImgLazaret from './../../../images/sites/lazaret.webp';
import PortImgSesperi from './../../../images/sites/sesperi.webp';
import PortImgSelfTissus from './../../../images/sites/self-tissus.webp';
import PortImgAthis from './../../../images/sites/athis-marbre.webp';
import PortImgAfcf from './../../../images/sites/afcf.webp';
import PortImgHaras from './../../../images/sites/haras-de-castellane.webp';
import PortImgMado from './../../../images/sites/jardin-de-mado.webp';
import PortImgArmelle from './../../../images/sites/armellebouret.webp';

const FilterList = ({ dataFilter, defaultTag, activeFilter }) => {
	return (
		<li className={`${activeFilter ? 'btn active' : 'btn btn-border-radius'}`} onClick={() => defaultTag(dataFilter)} >
			<Link to={"#"}>{dataFilter}</Link>
		</li>
	);
};

function PortfolioContent(props) {
	const [tag, setTag] = useState('Tout Voir');
	const [filteredImages, setFilterdImages] = useState([]);

	useEffect(() => {
		tag === 'Tout Voir' ? setFilterdImages(content) : setFilterdImages(content.filter(image => image.tag.find(element => element === tag)))
	}, [tag])

	// SETTER 
	const [site0, setSite0] = useState('')
	const [site1, setSite1] = useState('')
	const [site2, setSite2] = useState('')
	const [site3, setSite3] = useState('')
	const [site4, setSite4] = useState('')
	const [site5, setSite5] = useState('')
	const [site6, setSite6] = useState('')
	const [site7, setSite7] = useState('')
	const [site8, setSite8] = useState('')
	const [site9, setSite9] = useState('')
	const [site10, setSite10] = useState('')
	const [site11, setSite11] = useState('')
	const [site12, setSite12] = useState('')
	const [site13, setSite13] = useState('')
	const [site14, setSite14] = useState('')
	const [site15, setSite15] = useState('')
	const [site16, setSite16] = useState('')
	const [site17, setSite17] = useState('')


	useEffect(() => {
		axios.get(`${config.urlServer}api/sites`)
			.then(function (res) {
				setSite0(res.data.Composents[1].Data[0])
				setSite1(res.data.Composents[1].Data[1])
				setSite2(res.data.Composents[1].Data[2])
				setSite3(res.data.Composents[1].Data[3])
				setSite4(res.data.Composents[1].Data[4])
				setSite5(res.data.Composents[1].Data[5])
				setSite6(res.data.Composents[1].Data[6])
				setSite7(res.data.Composents[1].Data[7])
				setSite8(res.data.Composents[1].Data[8])
				setSite9(res.data.Composents[1].Data[9])
				setSite10(res.data.Composents[1].Data[10])
				setSite11(res.data.Composents[1].Data[11])
				setSite12(res.data.Composents[1].Data[12])
				setSite13(res.data.Composents[1].Data[13])
				setSite14(res.data.Composents[1].Data[14])
				setSite15(res.data.Composents[1].Data[15])
				setSite16(res.data.Composents[1].Data[16])
				setSite17(res.data.Composents[1].Data[17])
			})
			.catch(err => { })
	}, []);

	// Portfolio Content
	const content = [
		{
			thumb: PortImgHp,
			tag: ['site lié à des outils internes',],
			title: !site0.Titre ? "Haussmann Patrimoine" : site0.Titre,
			exetitle: !site0.Corps ? "Cabinet conseil en gestion de patrimoine" : site0.Corps,
			url: !site0.BTNUrl ? "https://www.haussmann-patrimoine.fr/" : site0.BTNUrl,
			alt: "Haussmann Patrimoine Cabinet conseil en gestion de patrimoine"
		},
		{
			thumb: PortImgWebere,
			tag: ['site lié à des outils internes',],
			title: !site1.Titre ? "Webere" : site1.Titre,
			exetitle: !site1.Corps ? "Votre chasseur immobilier" : site1.Corps,
			url: !site1.BTNUrl ? "https://www.webere.fr/" : site1.BTNUrl,
			alt: "Webere Votre chasseur immobilier"
		},
		{
			thumb: PortImgSesperi,
			tag: ['Site de gestion',],
			title: !site2.Titre ? "Sesperi" : site2.Titre,
			exetitle: !site2.Corps ? "École maternelle et élémentaire" : site2.Corps,
			url: !site2.BTNUrl ? "https://www.sesperi.com/" : site2.BTNUrl,
			alt: "Sesperi École maternelle et élémentaire"
		},
		{
			thumb: PortImgSifrein,
			tag: ['Site Vitrine',],
			title: !site3.Titre ? "Sifrein" : site3.Titre,
			exetitle: !site3.Corps ? "Exposition d'art" : site3.Corps,
			url: !site3.BTNUrl ? "https://www.sifrein.com/" : site3.BTNUrl,
			alt: "Sifrein Exposition d'art"
		},
		{
			thumb: PortImgDivin,
			tag: ['Site Vitrine',],
			title: !site4.Titre ? "Divin Poulet" : site4.Titre,
			exetitle: !site4.Corps ? "Opéra comique" : site4.Corps,
			url: !site4.BTNUrl ? "https://www.divinpoulet.com/" : site4.BTNUrl,
			alt: "Divin Poulet Opéra comique"
		},
		{
			thumb: PortImgEdilson,
			tag: ['Site Vitrine',],
			title: !site5.Titre ? "Edilson Show Paris" : site5.Titre,
			exetitle: !site5.Corps ? "Agence Edilson Show Paris Entertainment" : site5.Corps,
			url: !site5.BTNUrl ? "https://www.edilsonshow.com/" : site5.BTNUrl,
			alt: "Agence Edilson Show Paris Entertainment"
		},
		{
			thumb: PortImgSafran,
			tag: ['Site Vitrine',],
			title: !site6.Titre ? "Safran" : site6.Titre,
			exetitle: !site6.Corps ? "Bureau de représentation de DMC" : site6.Corps,
			url: !site6.BTNUrl ? "http://www.safranrp.com/" : site6.BTNUrl,
			alt: "Safran Bureau de représentation de DMC"
		},
		{
			thumb: PortImgCde,
			tag: ['Site de gestion',],
			title: !site7.Titre ? "CDE" : site7.Titre,
			exetitle: !site7.Corps ? "Conservatoire Des oiseaux Exotiques" : site7.Corps,
			url: !site7.BTNUrl ? "https://www.le-cde.com/" : site7.BTNUrl,
			alt: "CDE Conservatoire Des oiseaux Exotiques"
		},
		{
			thumb: PortImgGcp,
			tag: ['Site Vitrine',],
			title: !site8.Titre ? "Entrepreneurs CGP" : site8.Titre,
			exetitle: !site8.Corps ? "Club de gestion de patrimoine" : site8.Corps,
			url: !site8.BTNUrl ? "https://www.entrepreneurs-cgp.com/" : site8.BTNUrl,
			alt: "Entrepreneurs CGP Club gestion de patrimoine"
		},
		{
			thumb: PortImgPellenc,
			tag: ['site lié à des outils internes',],
			title: !site9.Titre ? "Pellenc Guarantee" : site9.Titre,
			exetitle: !site9.Corps ? "Sécateurs Vinion" : site9.Corps,
			url: !site9.BTNUrl ? "https://guarantee.pellenc.com/" : site9.BTNUrl,
			alt: "Pellenc Guarantee Sécateurs Vinion"
		},
		{
			thumb: PortImgMotorhell,
			tag: ['Site Vitrine',],
			title: !site10.Titre ? "MotorHell" : site10.Titre,
			exetitle: !site10.Corps ? "Motos custom" : site10.Corps,
			url: !site10.BTNUrl ? "https://www.motorhell.com/" : site10.BTNUrl,
			alt: "MotorHell Motos custom"
		},
		{
			thumb: PortImgLazaret,
			tag: ['Site Vitrine',],
			title: !site11.Titre ? "Lazareth" : site11.Titre,
			exetitle: !site11.Corps ? "Autos et motos" : site11.Corps,
			url: !site11.BTNUrl ? "https://www.by-lazareth.com/" : site11.BTNUrl,
			alt: "Lazareth Autos et motos"
		},
		{
			thumb: PortImgSelfTissus,
			tag: ['Site de gestion',],
			title: !site12.Titre ? "Self Tissus" : site12.Titre,
			exetitle: !site12.Corps ? "Boutiques de tissus" : site12.Corps,
			url: !site12.BTNUrl ? "https://franchise.selftissus.fr/accueil/" : site12.BTNUrl,
			alt: "Self Tissus Boutiques tissus"
		},
		{
			thumb: PortImgAthis,
			tag: ['Site Vitrine',],
			title: !site13.Titre ? "Athis Marbres" : site13.Titre,
			exetitle: !site13.Corps ? "Marbrerie de décoration" : site13.Corps,
			url: !site13.BTNUrl ? "https://athismarbres.com/" : site13.BTNUrl,
			alt: "Athis Marbres Marbrerie de décoration"
		},
		{
			thumb: PortImgAfcf,
			tag: ['Site Vitrine',],
			title: !site14.Titre ? "AFCF" : site14.Titre,
			exetitle: !site14.Corps ? "Amicale française des courreurs de fond" : site14.Corps,
			url: !site14.BTNUrl ? "https://www.afcf.org/" : site14.BTNUrl,
			alt: "AFCF Amicale française des courreurs de fond"

		},
		{
			thumb: PortImgHaras,
			tag: ['Site Vitrine',],
			title: !site15.Titre ? "Haras de Castellane" : site15.Titre,
			exetitle: !site15.Corps ? "Écurie de concours hippique" : site15.Corps,
			url: !site15.BTNUrl ? "http://harasdecastellane.fr/" : site15.BTNUrl,
			alt: "Haras de Castellane Écurie de concours hippique"
		},

		{
			thumb: PortImgMado,
			tag: ['Site de gestion',],
			title: !site16.Titre ? "Jardin de Mado" : site16.Titre,
			exetitle: !site16.Corps ? "Poteries et contenant pour végétaux" : site16.Corps,
			url: !site16.BTNUrl ? "https://www.lejardindemado.com/" : site16.BTNUrl,
			alt: "Jardin de Mado Poteries et contenant pour végétaux"
		},
		{
			thumb: PortImgArmelle,
			tag: ['Site Vitrine',],
			title: !site17.Titre ? "ArmelleBouret" : site17.Titre,
			exetitle: !site17.Corps ? "Photography" : site17.Corps,
			url: !site17.BTNUrl ? "http://www.armellebouret.com/" : site17.BTNUrl,
			alt: "ArmelleBouret Photography"

		},
	]

	return (
		<>
			<div className="feature-filters clearfix center m-b40">
				{<ul className="filters" data-toggle="buttons">
					<FilterList
						dataFilter="Tout Voir"
						defaultTag={setTag}
						activeFilter={tag === 'Show All' ? true : false}
					/>
					<FilterList
						dataFilter="Site Vitrine"
						defaultTag={setTag}
						activeFilter={tag === 'Site Vitrine' ? true : false}
					/>
					<FilterList
						dataFilter="Site de gestion"
						defaultTag={setTag}
						activeFilter={tag === 'Site de gestion' ? true : false}
					/>
					<FilterList
						dataFilter="site lié à des outils internes"
						defaultTag={setTag}
						activeFilter={tag === 'site lié à des outils internes' ? true : false}
					/>
				</ul>
				}
			</div>
			<div className="row sp20 ">
				{filteredImages.map((item, index) => (
					<div className="action-card col-lg-4 col-md-6 col-sm-6 col-12 mb-5" key={index}>
						<div className="portfolio-box style-3">
							<div className="portfolio-media">
								<a href={item.url} target="_blank" rel="noreferrer"><img src={item.thumb} style={{
									with: "100%",
									height: "100%"
								}} alt={item.alt} /></a>
							</div>
							<div className="portfolio-info">
								<h4 className="title"><a href={item.url} target="_blank" rel="noreferrer">{item.title}</a></h4>
								<span className="exe-title">{item.exetitle}</span>
							</div>
						</div>
					</div>
				))}
			</div>
		</>
	);
}

export default function PortMasonry(props) {
	return (
		<>
			<PortfolioContent />
		</>
	)

}
