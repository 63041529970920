import React from "react";

export default function Text1(props) {

    return (
        <>
            <div style={{ textAlign: props.text.alignText }}>
                <div style={{
                    textTransform: props.text.h,
                    textDecoration: props.text.decoration,
                    color: props.text.color
                }}>
                    <h2 style={{ fontSize: props.text.hsize, fontWeight: props.text.fontWeightH }}>
                        {props.text.title}
                    </h2>
                </div>
                <p style={{ fontSize: props.text.psize, fontWeight: props.text.fontWeight }}>
                    {props.text.p}
                </p>
            </div>
        </>
    )
}