import React from "react";

export default function List4(props) {
    return (
        <>
            <div>
                <div className="ms-3 mb-5">
                    {props.list &&
                        props.list.map((item) => (
                            <ui key={item.ID}>
                                <li className="puce-change"><p className="competence_titre text-uppercase">{item.Titre}</p></li>
                                <p><a a href={item.BTNUrl} className="link-hover-primary" target="_blank">{item.Corps}</a></p>
                            </ui>
                        ))}
                    {props.err &&
                        props.static.map((item, index) => (
                            <ui key={index}>
                                <li className="puce-change"><p className="competence_titre text-uppercase">{item.title}</p></li>
                                <p><a a href={item.BTNUrl} className="link-hover-primary" target="_blank">{item.corps}</a></p>
                            </ui>
                        ))
                    }
                </div>
            </div>
        </>
    )
}