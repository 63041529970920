import React from 'react';
import Slider from "react-slick";

// Picture
import winDevLogo from '../../../images/partners/logo1.webp';
import winDevMobileLogo from '../../../images/partners/logo2.webp';
import webDevLogo from '../../../images/partners/logo3.webp';
import pcSoftLogo from '../../../images/partners/logo-pcsoft.webp';
import reactLogo from '../../../images/partners/react-logo.webp';
import gatsbyLogo from '../../../images/partners/gatsby-logo.webp';
import nodeLogo from '../../../images/partners/nodejs-logo.webp';
// import reactNativeLogo from '../../../images/partners/react-native-logo.PNG';

const content = [
	{
		src: winDevLogo,
		site: "https://pcsoft.fr/windev/index.html"
	},
	{
		src: winDevMobileLogo,
		site: "https://pcsoft.fr/windevmobile/index.html"
	},
	{
		src: webDevLogo,
		site: "https://pcsoft.fr/webdev/index.html"
	},
	{
		src: pcSoftLogo,
		site: "https://pcsoft.fr/"
	},
	{
		src: reactLogo,
		site: "https://fr.legacy.reactjs.org/"
	},
	// {
	// 	src: reactNativeLogo,
	// 	site: "https://reactnative.dev/"
	// },
	{
		src: gatsbyLogo,
		site: "https://www.gatsbyjs.com/"
	},
	{
		src: nodeLogo,
		site: "https://nodejs.org/en"
	}
]

export default function LogoCarousel(props) {

	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 5,
		slidesToScroll: 2,
		autoplay: true,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 5,
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 4,
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 3,
				}
			},
			{
				breakpoint: 360,
				settings: {
					slidesToShow: 2,
				}
			}
		]
	};

	// CONST 
	const section = {
		title: !props.sec4.Titre ? "Nos Savoir-Faire" : props.sec4.Titre,
		p: !props.sec4.Corps ? "Notre palette d'outils nous permet de développer une solution qui convient pour votre métier et vos usages." : props.sec4.Corps
	}

	return (
		<>
			<div className="section-area section-sp2 bg-white mt-5 mb-5">
				<div className="container">
					<div className="heading-bx text-center">
						<h2 className="title-head m-b0">{section.title}</h2>
						<p className="m-b0">{section.p}</p>
					</div>
					<Slider {...settings} className="slider-sp10 arrow-none">
						{content &&
							content.map((item, index) => (
								<div className="slider-item" key={index}>
									<div className="client-logo">
										<a href={item.site} target="_blank" rel="noreferrer">
											<img
												// src={`${item.img}__PNG`}
												src={item.src}
												style={{
													width: "auto !important"
												}}
												alt={item.Titre}
											/>
										</a>
									</div>
								</div>
							))}
					</Slider >
				</div>
			</div>
		</>
	);
}
