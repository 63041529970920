import React from "react";

// Element
import Count from '../elements/counter/counter-sensor';

// Text
import Text1 from "../elements/text/text-1";

export default function CounterText(props) {
    const content = {
        counter: !props.counter.Corps ? "80" : props.counter.Corps,
        entitledCounter: !props.counter.Titre ? "Projets Yogan" : props.counter.Titre,
    }

    const text = {
        title: !props.data.Titre ? "Notre expérience, Nos compétences" : props.data.Titre,
        p: !props.data.Corps ? "L'activité de chaque entreprise est unique avec des besoins spécifiques en matière de gestion et de fonctionnement. Pendant plus de 18 ans, YOGAN Développement s'est spécialisé dans la réalisation d'outils et solutions sur mesure pour des clients aux activités variées et exigeantes." : props.data.Corps,
        h: "capitalize",
        psize: "18px"
    }

    return (
        <>
            <div className="about-head-bx heading-bx">
                <div className="about-counter">
                    <div className="counter-bx"><Count counter={content.counter} />+</div>
                    <h5 className="text-capitalize">{content.entitledCounter}</h5>
                </div>
                <div className="head-in mb-5">
                    <Text1 text={text} />
                </div>
            </div>
        </>
    )
}