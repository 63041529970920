import React from 'react';
import { Link } from 'react-router-dom';

// Picture
import logoDash from '../../../images/partners/logo_easy.webp';
import logoEasy from '../../../images/partners/logo_dashlane.webp';
import logoKeepass from '../../../images/partners/logo-keepass.PNG';

const content = [
	{
		src: logoDash,
		alt: "Logo Dashlane",
		entreprise: "Dashlane",
		site: "https://www.dashlane.com/fr",
		description: "Gestionnaire de mots de passe ultra sécurisé",
		stylecard: "portfolio-media style-card-type-2"
	},
	{
		src: logoEasy,
		alt: "Logo Easy Service Informatique",
		entreprise: "Easy Service Informatique",
		site: "https://www.easyserviceinformatique.com/",
		description: "Prestataire de service informatique",
		stylecard: "portfolio-media style-card-type-3"
	},
	{
		src: logoKeepass,
		alt: "Logo Keepass",
		entreprise: "Keepass",
		site: "https://keepass.fr/",
		description: "Gestionnaire de mots de passe",
		stylecard: "portfolio-media style-card-type-2"
	}
]

export default function LogoCarouselPartners(props) {
	return (
		<>
			<div className='container mt-2 mb-2'>
				<div className="row sp20">
					{content.map((item, index) => (
						<div className="action-card col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
							<div className="portfolio-box style-2 style-card-white">
								<div className={item.stylecard}>
									<img src={item.src} alt={item.alt} style={{
										width: "auto !important"
									}} />
								</div>
								<div className="portfolio-info">
									<h4 className="title"><a href={item.site}>{item.entreprise}</a></h4>
									<span className="exe-title">{item.description}</span>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
}
