import React, { useState, useEffect } from 'react';

// get Api
import axios from "axios";
import config from "../../../config.json";

// Markdown 
import ReactMarkdown from "react-markdown";

// Layout
import Header from "../../layout/header/header";
import Footer from "../../layout/footer/footer3";

// Element
import MainSlider from '../../elements/main-slider/slider1';
import PortSlider from "../../elements/portfolio/portfolio-slider-3";
import LogoCarousel from "../../elements/client-logo/logo-carousel";
import SEO from '../../elements/seo/seo';
import TextVideo1 from '../../elements/video/text-video-1';

// Modal
import ModalContactRapide from '../../elements/modal/modal-3';

// Title
import Title1 from '../../elements/title/title-1';

// Button 
import Button1 from '../../elements/button/button-1';

export default function Index(props) {

	// modal Contact Rapide
	const [openModal2, setOpenModal2] = useState(true)

	// SETTER
	const [meta, setMeta] = useState('')
	const [sec1, setSec1] = useState('')
	const [sec2, setSec2] = useState('')
	const [sec3, setSec3] = useState('')
	const [sec4, setSec4] = useState('')

	// Vidéo
	const [videoId, setvideoId] = useState('')
	const [videoPicture, setvideoPicture] = useState('https://rest2.yogan.pro/GETPhoto/WDA_c1c6bd2a-9905-49f8-95c7-03047ac83a0a_Photo1')

	useEffect(() => {
		axios.get(`${config.urlServer}api/accueil`)
			.then(function (res) {
				setMeta(res.data)
				setSec1(res.data.Composents[2])
				setvideoId(res.data.Composents[2].Data[0].Corps)
				setvideoPicture(res.data.Composents[2].Data[1].img)
				setSec2(res.data.Composents[3])
				setSec3(res.data.Composents[4])
				setSec4(res.data.Composents[5])
			})
		axios.get(`${config.urlServer}api/visit`)
	},[]);

	// CONST 
	const metaData = {
		title: !meta.TitreMeta ? "Yogan Développement : la fabrique de logiciel" : meta.TitreMeta,
		description: !meta.description ? "Développeur français indépendant d'applications et solution de gestion d'activités commerciales et associatives pour les professionnels." : meta.description,
	}

	const button = {
		BTNLib: !sec2.BTNLib ? "voir nos projets" : sec2.BTNLib,
		BTNUrl: !sec2.BTNUrl ? "/sites" : sec2.BTNUrl,
		style: "center"
	}

	return (
		<>
			{/* Dynamic Meta Tags */}
			<SEO
				title={metaData.title}
				description={metaData.description}
				canonical=""
			/>

			{/* if openModal == false then we runing Modal component  */}
			{/* {openModal2 && <ModalContactRapide setOpenModal2={setOpenModal2} />} */}

			<Header />

			<MainSlider />

			{/* <!-- Logiciels métiers Start --> */}
			<TextVideo1 sec1={sec1} videoId={videoId} videoPicture={videoPicture} />
			{/* <!-- Logiciels métiers End --> */}


			{/* <!-- Qui sommes-nous Start --> */}
			<div className="content-block" id="content-area">
				<div className="section-area section-sp2 bg-white">
					<div className="section-area">
						<div className="container">
							<div className="section">

								<Title1 title={sec2.Titre} textTransform={"capitalize"} />

								<ReactMarkdown>{sec2.Corps}</ReactMarkdown>

								<Button1 button={button} />
								
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <!-- Qui sommes-nous End --> */}


			{/* <!-- Nos solutions Start --> */}
			<PortSlider sec3={sec3} />
			{/* <!-- Nos solutions End --> */}

			{/* <!-- Nos savoir-faire Start --> */}
			<LogoCarousel sec4={sec4} />
			{/* <!-- Nos savoir-faire End --> */}


			<Footer />
		</>
	);
}