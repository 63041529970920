import React, { useState, useEffect } from 'react';

// Get Api
import axios from "axios";
import config from "../../../config.json";

// Markdown 
import ReactMarkdown from "react-markdown";

// Layout
import Header from "../../layout/header/header";
import Footer from "../../layout/footer/footer3";

// Element
import Banner from '../../elements/banner/banner-2';
import SEO from '../../elements/seo/seo';

// List
import List1 from '../../elements/list/list-1';

// Title
import Title1 from '../../elements/title/title-1';

export default function Web() {

	// SETTER 
	const [meta, setMeta] = useState('') // metaData
	const [banner, setBanner] = useState('') // banner data
	const [sec1, setSec1] = useState('')
	const [data, setData] = useState('')

	// Manage static data 
	const [err, setErr] = useState(false)

	// List 1 
	const [titleList1, setTitleList1] = useState('')
	const [dataList1, setDataList1] = useState([])

	useEffect(() => {
		axios.get(`${config.urlServer}api/web`)
			.then(function (res) {
				setMeta(res.data)
				setBanner(res.data.Composents[0])
				setSec1(res.data.Composents[1])
				setData(res.data.Composents[3].Corps)

				// List 1 
				setTitleList1(res.data.Composents[2].Titre)
				setDataList1(res.data.Composents[2].Data)

			})
			.catch(err => {
				setErr(true)
			})
	},[]);


	// CONST 
	const metaData = {
		title: !meta.TitreMeta ? "Outils et solutions en ligne" : meta.TitreMeta,
		description: !meta.description ? "Développeur et concepteur d'application web pour les professionnels." : meta.description,
		canonical: !meta.URLCanonical ? "web" : meta.URLCanonical
	}

	const bannerContent = {
		title: !banner.Titre ? "Web" : banner.Titre,
		entitled: !banner.Corps ? "Web" : banner.Corps
	}

	const content = {
		t1: !sec1.Titre ? " outils et solutions en ligne" : sec1.Titre,
		corps: !sec1.Corps ? "Il est souvent vital pour une entreprise d'être présente sur internet notamment pour assurer sa visibilité commerciale et s'adapter aux habitudes des clients. Que ce soit pour la création d'une nouvelle activité, reprendre et mettre à jour un ancien site web ou encore accompagner une entreprise dans son entrée sur internet, nous pouvons vous apporter notre expertise, vous conseiller et vous proposer des solutions adaptés à vos besoins." : sec1.Corps,
	}

	// TITLE 
	const titleList = {
		titleList1: !titleList1 ? "Quelsques-un des projets qui nous ont été confiés :" : titleList1,
	}

	// STATIC DATA
	const listStatic1 = [
		{
			title: "vente en ligne",
			corps: "Maquettage, Catalogue, Commande, Paiement en ligne, Facturation, Gestion du contenu rédactionnel ..."
		},
		{
			title: "services en ligne",
			corps: "Web app, Choix de service avec options, Localisation gps, Paiement en ligne, Suivi de situation en direct ..."
		},
		{
			title: "conseil en gestion de patrimoine",
			corps: "Contenus personnalisés, Questionnaires, Suivi de compte en ligne, Rédactionnel, Formulaires complexes ..."
		},
		{
			title: "intranets, gestion d'activité en ligne",
			corps: "Outils de gestions en ligne privés pour télétravail avec accès complet à l'outil de gestion."
		},
		{
			title: "site vitrines & plaquettes",
			corps: "Présentation de services, Plaquettes publicitaires, Galleries d'art ..."
		},
		{
			title: "compétences internet",
			corps: "En travaillant sur de nombreux projets variés, nous avons acquis de nombreuses compétences web dans la réalisation de modules et interfaces adaptés aux besoins du projet."
		}
	]

	return (
		<>
			{/* Menu */}
			<Header />

			{/* Dynamic Meta Tags */}
			<SEO
				title={metaData.title}
				description={metaData.description}
				canonical={metaData.canonical}
			/>

			<Banner title={bannerContent.title} entitled={bannerContent.entitled} navTitle="Accueil" navLink="/" />

			<div className="page-content bg-white">
				<div className="content-block" id="content-area">
					<div className="section-area section-sp1 bg-gray">
						<div className="container">
							<div className="row">
								<div className="col-lg-12 col-md-6">

									{/* <!-- Outils et solutions + projet confié Start --> */}
									<Title1 title={content.t1} textTransform={"capitalize"} />
									<ReactMarkdown className='mb-5'>{content.corps}</ReactMarkdown>
									{/* <!-- Outils et solutions + projet confié End --> */}

									{/* <!-- Interfaces & outils internet + services annexes Start --> */}
									<List1 title={titleList.titleList1} list={dataList1} static={listStatic1} err={err} />
									<ReactMarkdown className='mb-5'>{data}</ReactMarkdown>
									{/* <!-- Interfaces & outils internet + services annexes End --> */}

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}