import React from 'react';
import { Link } from 'react-router-dom';

export default function Error(props) {
	return (
		<>
			<div className="account-form error-404">
				<div className="account-form-inner">
					<div className="account-container">
						<div className="error-page">
							<h2 className="error-title">4<span></span>4</h2>
							<h5>Page Introuvable</h5>
							<p>La page que vous recherchez semble introuvable.</p>
							<div className="">
								<Link to="/" className="btn button-md radius-xl m-b15 m-r5">Retour</Link>
								<Link to="/" className="btn button-md radius-xl m-b15 outline outline-2">Page d'acceuil</Link>
							</div>
						</div>
					</div>
				</div>
			</div>

		</>
	);
}