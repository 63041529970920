import React from "react";

export default function List5(props) {
    return (
        <>
            {props.list &&
                props.list.map((item) =>
                    <div className="timeline-box">
                        <ul className="list-circle" key={item.ID}>
                            <li>{item.Corps}</li>
                        </ul>
                    </div>
                )}
            {props.err &&
                props.static.map((item, index) => (
                    <div className="timeline-box">
                        <ul className="list-circle" key={index}>
                            <li>{item.Corps}</li>
                        </ul>
                    </div>
                ))
            }
        </>
    )
}